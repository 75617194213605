import { Stack, Typography } from '@mui/material';
import type { OptionProps } from '@/components/hotels/search-fields-hotels/components/select-hotel/components/option/option.types';
import palette from '@/components/common/styles/color.module.scss';
export default function Option(props: OptionProps) {
  const { option } = props;
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      m={1}
      ml='5px'
      padding={{
        xs: '4px 8px',
        md: '10px 8px',
      }}
      sx={{
        borderRadius: '6px',
        '&:hover': {
          backgroundColor: palette.primary_lighter_40,
          cursor: 'pointer',
        },
      }}>
      <Stack direction='row'>
        <Stack
          direction='row'
          mt='3px'
          mr={1}>
          <Stack className={option.group === 'hotel' ? 'icon-building' : 'icon-location'}></Stack>
        </Stack>
        <Stack>
          <Typography variant='b2_bold'>{option.label}</Typography>
          <Typography variant='c1_regular'>{option.place || ''}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
