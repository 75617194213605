import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio } from '@mui/material';
import { useState } from 'react';
import DrawerView from './views/drawer-view/drawer-view';
import SelectView from './views/select-view/select-view';
import useDevice, { DeviceEnum } from '../hooks/use-device';
import type { ResponsiveSelectProps, SelectComponentTotalProps } from './responsive-select.types';
import palette from '@/components/common/styles/color.module.scss';

export default function ResponsiveSelect(props: ResponsiveSelectProps & SelectComponentTotalProps) {
  const {
    sx,
    error,
    renderDrawerOptions,
    multiple,
    value,
    onClose,
    onOpen,
    open,
    disabled,
    paperSx,
    onChange,
    label,
    options,
    customSelectOption,
    className,
    dialogLabel,
    selectLabel,
    icon,
    selectCustomWidth,
    renderValue,
    iconLess,
  } = props;
  const device = useDevice();
  const [showDrawer, setShowDrawer] = useState(false);
  const isMobile = device === DeviceEnum.MOBILE;
  const selectViewChildren =
    customSelectOption ??
    options?.map((option, index) => (
      <MenuItem
        sx={{
          position: 'relative',
          '&::after': {
            position: 'absolute',
            left: '8px',
            right: '8px',
            bottom: 0,
            height: ' 1px',
            backgroundColor: `rgba(${palette.gray}, 0.4)`,
          },
          '&:last-child': {
            '&::after': {
              display: 'none',
            },
          },
        }}
        value={option.value}
        key={index}>
        <FormControlLabel
          sx={{
            span: {
              '&:last-child': {
                fontSize: '14px',
                lineHeight: '20px',
                color: palette.black,
              },
            },
            input: {
              '& + span': {
                svg: {
                  fontSize: '16px',
                },
              },
            },
          }}
          checked={value === option.value}
          value={option.value}
          control={<Radio />}
          label={option.text}
        />
      </MenuItem>
    ));
  return (
    <>
      <FormControl
        size={'small'}
        fullWidth
        disabled={isMobile || disabled}
        error={error}>
        <InputLabel>{label}</InputLabel>
        <SelectView
          onClose={onClose}
          onOpen={onOpen}
          open={open}
          multiple={multiple}
          sx={sx}
          paperSx={paperSx}
          iconLess={iconLess}
          className={className}
          renderValue={renderValue}
          selectCustomWidth={selectCustomWidth}
          icon={icon}
          value={value}
          disabled={disabled}
          onChange={onChange}
          setShowDrawer={() => setShowDrawer(true)}
          label={selectLabel || label}>
          {selectViewChildren}
        </SelectView>
      </FormControl>
      <DrawerView
        showDrawer={showDrawer}
        closeDrawer={() => setShowDrawer(false)}
        label={dialogLabel || label}>
        {renderDrawerOptions(options, setShowDrawer)}
      </DrawerView>
    </>
  );
}
