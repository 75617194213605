import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import type { FlightSearchData, SearchFieldsFlightsProps } from './search-fields-flights.types';
import {
  generateSearchParamsFlight,
  isDisabledDecreasePassengerCounter,
  isDisabledIncreasePassengerCounter,
} from './functions';
import { Box, Stack } from '@mui/material';
import { useLanguage } from '@/components/common/language/language';
import { CabinClass, FlightType, LocationType } from '@/services/flights/flight.types';
import type { Option } from '@/components/common/responsive-select/responsive-select.types';
import { searchesFlightSave } from '@/redux/flight-searches-slice';
import ResponsiveRadioSelect from '@/components/common/responsive-radio-select/responsive-radio-select';
import { toDateObject } from '@/components/common/date-picker/functions';
import ResponsiveCounterSelect from '@/components/common/responsive-counter-select/responsive-counter-select';
import type { DateObject } from '@/components/common/date-picker/type';
import { airportsInfoSave } from '@/redux/airports-info-slice';
import { getAirportInformation, getNewCodes } from '@/components/flights/function';
import { useState } from 'react';
import { useAppSelector } from '@/redux/hooks';
import SelectDate from '@/components/flights/search-fields-flights/components/select-date/select-date';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import OriginDestination from '@/components/home/hero-section/components/origin-destination/origin-destination';

import { BOOKING_URL } from '@/components/common/constant/constant';
import { Button } from '@/components/common/button/button';

export default function SearchFieldsFlights(props: SearchFieldsFlightsProps) {
  const { hiddenSearchBtn, buttonCallback } = props;
  const router = useRouter();
  const device = useDevice();
  const languageJson = useLanguage();
  const dispatch = useDispatch();
  const { airportsInfo } = useAppSelector(state => state.airportsInfo);
  const isMobile = device === DeviceEnum.MOBILE;
  const [flightSearchData, setFlightSearchData] = useState<FlightSearchData>({
    tripType: FlightType.ONEWAY,
    flightClass: CabinClass.ECONOMY,
    originDestination: {
      origin: {
        label: '',
        code: '',
        type: LocationType.CITY,
        country: '',
      },
      destination: {
        label: '',
        code: '',
        type: LocationType.CITY,
        country: '',
      },
    },
    passengers: {
      adult: 1,
      child: 0,
      infant: 0,
    },
    rangeDate: {
      start: toDateObject(new Date()),
    },
  });
  const passengerTypes: Option[] = [
    {
      value: 'adult',
      text:
        languageJson.state.passengers_list.adult.title +
        '-' +
        languageJson.state.passengers_list.adult.title,
    },
    {
      value: 'child',
      text:
        languageJson.state.passengers_list.child.title +
        '-' +
        languageJson.state.passengers_list.child.title,
    },
    {
      value: 'infant',
      text:
        languageJson.state.passengers_list.infant.title +
        '-' +
        languageJson.state.passengers_list.infant.title,
    },
  ];
  const flightTypeOptions: Option[] = [
    {
      value: FlightType.ONEWAY,
      text: languageJson.state.trip_type_list.oneway,
    },
    {
      value: FlightType.RETURN,
      text: languageJson.state.trip_type_list.return,
    },
  ];
  const flightClassOptions: Option[] = [
    {
      value: CabinClass.ECONOMY,
      text: languageJson.state.class_flight_list.economy,
    },
    {
      value: CabinClass.BUSINESS,
      text: languageJson.state.class_flight_list.business,
    },
    {
      value: CabinClass.FIRST,
      text: languageJson.state.class_flight_list.first,
    },
  ];
  const translateCodes = async () => {
    dispatch(
      airportsInfoSave(
        (await getAirportInformation({
          airportCodes: getNewCodes(
            [
              flightSearchData.originDestination.destination.code,
              flightSearchData.originDestination.origin.code,
            ],
            airportsInfo || []
          ),
        })) || []
      )
    );
  };
  const updateSearchData = (stateObj: object) => {
    setFlightSearchData({ ...flightSearchData, ...stateObj });
  };
  const updateOriginDestinationState = (values: any, keys: any) => {
    keys.forEach((key: string, index: number) => {
      key &&
        updateSearchData({
          originDestination: {
            ...flightSearchData.originDestination,
            [key]: values[index],
          },
        });
    });
  };
  const updatePassenger = (passenger: Option, mode: string) => {
    let passengersState = { ...flightSearchData.passengers };
    switch (mode) {
      case 'increase':
        passengersState = {
          ...passengersState,
          [passenger.value as keyof typeof passengersState]:
            passengersState[passenger.value as keyof typeof passengersState] + 1,
        };
        break;
      case 'decrease':
        passengersState = {
          ...passengersState,
          [passenger.value as keyof typeof passengersState]:
            passengersState[passenger.value as keyof typeof passengersState] - 1,
        };
        break;
      default:
        passengersState = {
          ...passengersState,
          [passenger.value as keyof typeof passengersState]:
            passengersState[passenger.value as keyof typeof passengersState],
        };
        break;
    }
    updateSearchData({ passengers: passengersState });
  };
  const searchClick = () => {
    const paramsUrl = generateSearchParamsFlight(flightSearchData);
    translateCodes?.();
    dispatch(
      searchesFlightSave({
        data: flightSearchData,
        url: `${BOOKING_URL}/${router.locale}/flights?${paramsUrl}`,
      })
    );
    buttonCallback?.();
    window.location.href = `${BOOKING_URL}/${router.locale}/flights/searchresults?${paramsUrl}`;
  };
  function onChangeTripType(value: string | number | object | null) {
    if (typeof value === 'string') {
      updateSearchData({
        tripType: value,
        rangeDate: {
          ...flightSearchData.rangeDate,
          end: value === 'ONEWAY' ? undefined : flightSearchData.rangeDate.end,
        },
      });
    }
  }
  function onChangeFlightClass(value: string | number | object | null) {
    if (typeof value === 'string') {
      updateSearchData({ flightClass: value });
    }
  }
  function renderValueTripType(value: string | number | object | null) {
    return <>{flightTypeOptions.find(item => item.value === value)?.text}</>;
  }
  function renderValueFlightClass(value: string | number | object | null) {
    return <>{flightClassOptions.find(item => item.value === value)?.text}</>;
  }
  const isDisabledSearch = !(
    flightSearchData.originDestination.destination.code &&
    flightSearchData.originDestination.origin.code
  );
  return (
    <Stack
      id='FRONT_CONTROL-flight-form'
      mt={1.5}
      spacing={2}>
      <Stack
        direction='row'
        alignItems='center'
        spacing={2}>
        <ResponsiveRadioSelect
          label={languageJson.common.trip_type}
          value={flightSearchData.tripType}
          onChange={onChangeTripType}
          options={flightTypeOptions}
          renderValue={renderValueTripType}
        />
        <ResponsiveRadioSelect
          label={languageJson.common.flight_class}
          value={flightSearchData.flightClass}
          onChange={onChangeFlightClass}
          options={flightClassOptions}
          renderValue={renderValueFlightClass}
        />
      </Stack>
      <Stack spacing={2}>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          alignItems='center'
          spacing={2}>
          <Box width={1}>
            <OriginDestination
              destinationInputLabel={languageJson.common.destination}
              originInputLabel={languageJson.common.origin}
              value={flightSearchData.originDestination}
              displacement={() =>
                updateSearchData({
                  originDestination: {
                    ...flightSearchData.originDestination,
                    destination: flightSearchData.originDestination.origin,
                    origin: flightSearchData.originDestination.destination,
                  },
                })
              }
              onChange={(values: any, keys: any) => updateOriginDestinationState(values, keys)}
            />
          </Box>
          <Box width={1}>
            <SelectDate
              limit={{
                // start: toDateObject(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(new Date().getDate()))),
                start: toDateObject(new Date()),
                end: toDateObject(new Date(new Date().setFullYear(new Date().getFullYear() + 1))),
              }}
              setTripType={() =>
                updateSearchData({
                  tripType: flightTypeOptions[1].value,
                })
              }
              onChange={(mode: 'start' | 'end', date?: DateObject) =>
                updateSearchData({
                  rangeDate: {
                    ...flightSearchData.rangeDate,
                    [mode]: date,
                  },
                })
              }
              value={flightSearchData.rangeDate}
              isRange={flightSearchData.tripType !== FlightType.ONEWAY}
            />
          </Box>
        </Stack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={{
            xs: 'flex-end',
            md: 'center',
          }}
          justifyContent='space-between'
          spacing={2}>
          <Box width='100%'>
            <ResponsiveCounterSelect
              increasable={isDisabledIncreasePassengerCounter}
              decreasable={isDisabledDecreasePassengerCounter}
              searchData={flightSearchData}
              onChange={updatePassenger}
              value={flightSearchData.passengers}
              options={passengerTypes}
            />
          </Box>
          {!hiddenSearchBtn && (
            <Box width={{ xs: 'auto', md: 1 }}>
              <Button
                id='FRONT_CONTROL-flight-form-submit'
                disabled={isDisabledSearch}
                onClick={searchClick}
                variant='contained'
                fullWidth
                sx={{
                  px: '42px',
                }}>
                {languageJson.common.search}
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
