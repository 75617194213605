import queryString from 'query-string';
import type { ParamsDataType } from '@/components/flights/flights.types';
import type { FlightSearchData } from '@/components/flights/search-fields-flights/search-fields-flights.types';

export const generateSearchParamsFlight = (searchData: FlightSearchData): string => {
  const paramsData: ParamsDataType = {
    adult: searchData.passengers.adult.toString(),
    child: searchData.passengers.child.toString(),
    infant: searchData.passengers.infant.toString(),
    flightClass: searchData.flightClass,
    tripType: searchData.tripType,
    start: `${searchData.rangeDate.start.year}-${searchData.rangeDate.start.month}-${searchData.rangeDate.start.day}`,
    originCode: searchData.originDestination.origin.code,
    originType: searchData.originDestination.origin.type,
    originLabel: searchData.originDestination.origin.label,
    originCountry: searchData.originDestination.origin.country,
    destinationCode: searchData.originDestination.destination.code,
    destinationType: searchData.originDestination.destination.type,
    destinationLabel: searchData.originDestination.destination.label,
    destinationCountry: searchData.originDestination.destination.country,
  };
  if (searchData.rangeDate.end) {
    paramsData.end = `${searchData.rangeDate.end.year}-${searchData.rangeDate.end.month}-${searchData.rangeDate.end.day}`;
  }
  return queryString.stringify(paramsData, { arrayFormat: 'index' });
};
export const isDisabledIncreasePassengerCounter = (
  searchData: FlightSearchData,
  index: number
): boolean => {
  switch (index) {
    case 0:
      return !(
        searchData.passengers.adult < 9 &&
        searchData.passengers.adult + searchData.passengers.child < 9
      );
    case 1:
      return !(
        searchData.passengers.child < 8 &&
        searchData.passengers.adult + searchData.passengers.child < 9
      );
    case 2:
      return !(searchData.passengers.infant < searchData.passengers.adult);
    default:
      return false;
  }
};
export const isDisabledDecreasePassengerCounter = (
  searchData: FlightSearchData,
  index: number
): boolean => {
  switch (index) {
    case 0:
      return !(
        searchData.passengers.adult > 1 &&
        searchData.passengers.adult > searchData.passengers.infant
      );
    case 1:
      return !(searchData.passengers.child > 0);
    case 2:
      return !(searchData.passengers.infant > 0);
    default:
      return false;
  }
};
