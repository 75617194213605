import React from 'react';
import { Box } from '@mui/material';
import palette from '@/components/common/styles/color.module.scss';

const ScrollUp = () => {
  return (
    <Box
      position='fixed'
      zIndex={3}
      display='flex'
      left={{ xs: 20, md: 20 }}
      bottom={{ xs: 80, md: 16 }}
      sx={{ cursor: 'pointer' }}>
      <Box
        onClick={() => {
          document.body.scrollIntoView({ behavior: 'smooth' });
        }}
        sx={{
          color: 'white',
          backgroundColor: palette.primary,
          position: 'relative',
          width: '50px',
          height: '50px',
          borderRadius: 9999,
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.16)',
          '&:hover': {
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
          },
        }}>
        <Box
          sx={{
            top: '50%',
            left: '50%',
            transform: ' translate(-50%,-50%)',
            position: 'absolute',
            fontSize: 28,
          }}
          className='icon-chevron-light-up'></Box>
      </Box>
    </Box>
  );
};

export default ScrollUp;
