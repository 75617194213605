import React from 'react';
import type { TourCardProps } from './tour-card.types';
import { Box, Rating, Stack, Typography } from '@mui/material';
import type { SliderFullPaginationPropsImage } from '@/components/tours/page/index.types';
import { SliderFullPagination } from '@/components/tours/page/components';
import palette from '@/components/common/styles/color.module.scss';
import { Badge } from '../badge';
import Link from 'next/link';
import { generateSingleTourUrl } from '../functions/url-generator';
import Image from '../image/image';
import useDevice, { DeviceEnum } from '../hooks/use-device';

export const TourCard: React.FC<TourCardProps> = ({
  id,
  slug,
  title,
  from_price,
  description,
  duration,
  duration_type,
  media,
  rates,
  styles,
  date,
  tour_leader,
}) => {
  // Use Hooks
  const isMobile = useDevice() === DeviceEnum.MOBILE;

  // Functions
  function formatDate(date: string) {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options);
  }

  const generateSliderImage: SliderFullPaginationPropsImage[] | undefined = React.useMemo(() => {
    return media?.slice(0, 1).map(({ original_url, original_name, property }) => {
      return {
        src: original_url,
        alt: property?.alt || '',
        title: original_name,
        url: original_url,
      };
    });
  }, [media]);

  let timeDuration = '/ ';
  if (!!duration && !!duration_type) {
    timeDuration += `${duration} ${duration_type}`;
  } else if (!!date) {
    timeDuration += formatDate(date);
  }
  return (
    <Stack
      justifyContent='space-between'
      height={1}
      spacing={1}>
      <Stack
        justifyContent='space-between'
        spacing={1}>
        {!!slug ? (
          <Link
            href={generateSingleTourUrl(id, slug, false)}
            style={{
              color: 'black',
            }}>
            <Box
              height={{
                xs: '168px',
                md: '223px',
              }}>
              {!!generateSliderImage && (
                <SliderFullPagination
                  size='sm-auto'
                  images={generateSliderImage}
                  borderRadius='5px'
                />
              )}
            </Box>
            <Typography
              className='verticalEllipsis'
              sx={{
                typography: {
                  xs: 'b1_bold',
                  md: 't3_bold',
                },
                WebkitLineClamp: 2,
              }}>
              {title}
            </Typography>
          </Link>
        ) : (
          <>
            <Box
              height={{
                xs: '168px',
                md: '223px',
              }}>
              {!!generateSliderImage && (
                <SliderFullPagination
                  size='sm-auto'
                  images={generateSliderImage}
                  borderRadius='5px'
                />
              )}
            </Box>
            <Typography
              className='verticalEllipsis'
              sx={{
                typography: {
                  xs: 'b1_bold',
                  md: 't2_bold',
                },
                WebkitLineClamp: 2,
              }}>
              {title}
            </Typography>
          </>
        )}
      </Stack>
      <Stack
        justifyContent='space-between'
        spacing={1}>
        {!!rates && (
          <Stack
            direction='row'
            spacing={1}>
            <Rating
              readOnly
              value={rates.average}
              precision={1}
              sx={{
                color: palette.warning,
              }}
            />
            <Typography
              sx={{
                typography: {
                  xs: 'c1_regular',
                  md: 'b1_regular',
                },
              }}>
              {`(${rates.count})`}
            </Typography>
          </Stack>
        )}

        {!!styles && (
          <Stack direction='row'>
            <Badge
              size={isMobile ? 'sm' : 'lg'}
              text={styles.text}
              color={styles.color}
            />
          </Stack>
        )}
        {!!tour_leader && (
          <Stack direction='row'>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent={{
                xs: 'flex-start',
                md: 'center',
              }}
              spacing={1}
              sx={{
                img: {
                  width: 1,
                  height: 1,
                  maxWidth: {
                    xs: '35px',
                    md: '50px',
                  },
                  maxHeight: {
                    xs: '35px',
                    md: '50px',
                  },
                  borderRadius: '999px',
                },
              }}>
              <Image
                width={96}
                height={96}
                title={tour_leader.thumbnail?.name || ''}
                alt={tour_leader.thumbnail?.name || ''}
                src={tour_leader.thumbnail.url}
              />
              <Typography variant='b2_bold'>{tour_leader.name}</Typography>
            </Stack>
          </Stack>
        )}

        <Stack
          spacing={1}
          flexGrow={1}>
          <Typography
            sx={{
              typography: {
                xs: 'b2_bold',
                md: 't3_bold',
              },
              color: palette.new_black,
            }}>
            {`${from_price} $ ${timeDuration}`}
          </Typography>

          <Typography
            className='verticalEllipsis'
            sx={{
              typography: {
                xs: 'c1_regular',
                md: 't3_regular',
              },
              WebkitLineClamp: 2,
            }}>
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
