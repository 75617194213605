import { Stack, Typography } from '@mui/material';
import { LocationType } from '@/services/flights/flight.types';
import { useLanguage } from '@/components/common/language/language';
import type { OptionProps } from '@/components/home/hero-section/components/origin-destination/components/option/option.types';
import palette from '@/components/common/styles/color.module.scss';

export default function Option(props: OptionProps) {
  const { option } = props;
  const languageJson = useLanguage();
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      m={1}
      ml='5px'
      padding={{
        xs: '4px 8px',
        md: '10px 8px',
      }}
      sx={{
        borderRadius: '6px',
        '&:hover': {
          backgroundColor: palette.primary_lighter_40,
          cursor: 'pointer',
        },
      }}
      className={option.class}>
      <Stack direction='row'>
        <Stack
          direction='row'
          mt='3px'
          mr={1}>
          <Stack
            className={
              option.type === LocationType.AIRPORT ? 'icon-airplane' : 'icon-location'
            }></Stack>
        </Stack>
        <Stack>
          <Typography variant='b2_bold'>
            {option.label}
            {option.type === LocationType.CITY && (
              <Typography variant='c1_regular'>
                &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
                {languageJson.common.all_airports}
              </Typography>
            )}
          </Typography>
          <Typography variant='c1_regular'>
            {(option.city ? option.city + ' - ' : '') + option.country}
          </Typography>
        </Stack>
      </Stack>
      <Typography variant='b2_regular'>{option.code}</Typography>
    </Stack>
  );
}
