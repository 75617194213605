import { Box, Dialog, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import Gregory from '@/components/common/date-picker/calendar/gregory';
import Persian from '@/components/common/date-picker/calendar/persian';
import HeaderFooterCalendar from '@/components/common/date-picker/component/header-footer-calendar/header-footer-calendar';
import DatePicker from '@/components/common/date-picker/date-picker';
import { isAfterDate, isBeforeDate } from '@/components/common/date-picker/functions';
import { localeGenerator } from '@/components/common/date-picker/locale';
import type { DateObject } from '@/components/common/date-picker/type';
import DualView from '@/components/common/date-picker/view/dual-view';
import ScrollView from '@/components/common/date-picker/view/scroll-view';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import { useLanguage } from '@/components/common/language/language';
import { TextFieldModified } from '@/components/common/textfield-modified/textfield-modified';
import type { ConfigCalendar } from '@/components/flights/search-fields-flights/search-fields-flights.types';
import type { SelectDateProps } from './select-date.types';
import palette from '@/components/common/styles/color.module.scss';

export default function SelectDate(props: SelectDateProps) {
  const { value, onChange, labelFrom, hiddenFooter, limit } = props;
  const device = useDevice();
  const languageJson = useLanguage();
  const router = useRouter();
  const [isShowModalDatepicker, setIsShowModalDatepicker] = useState(false);
  const [isShowModalDatepickerDesktop, setIsShowModalDatepickerDesktop] = useState(false);
  const [datepickerMode, setDatepickerMode] = useState('start');
  const [localeConfigDatepicker, setLocaleConfigDatepicker] = useState<ConfigCalendar>({
    calendar: Gregory,
    locale: localeGenerator(languageJson.common.datepicker),
  });
  const datepickerDescktopRef = useRef<HTMLDivElement>(null);
  const startInput = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLocaleConfigDatepicker({
      ...localeConfigDatepicker,
      locale: localeGenerator(languageJson.common.datepicker),
    });
  }, [router.locale]);

  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, [isShowModalDatepickerDesktop]);

  const clickHandler = (e: any) => {
    if (
      isShowModalDatepickerDesktop &&
      !e.composedPath().includes(datepickerDescktopRef.current) &&
      !e.composedPath().includes(startInput.current)
    ) {
      setIsShowModalDatepickerDesktop(false);
    }
  };
  const selectDatepickerRange = (date: DateObject) => {
    if (datepickerMode === 'start') {
      if (!value?.end || isBeforeDate(value.end, date)) {
        onChange(date, 'start');
        setDatepickerMode('end');
      } else {
        onChange(date, 'end');
        setDatepickerMode('start');
      }
    } else if (datepickerMode === 'end') {
      if (value.start)
        if (isAfterDate(value.start, date)) {
          onChange(date, 'end');
          setDatepickerMode('start');
        } else {
          onChange(date, 'start');
          setDatepickerMode('end');
        }
      setIsShowModalDatepicker(false);
      setIsShowModalDatepickerDesktop(false);
    }
  };

  const startDate = value?.start
    ? new Date(value.start.year, value.start.month - 1, value.start.day).toLocaleString(
        router.locale,
        {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        }
      )
    : '';
  const endDate = value?.end
    ? new Date(value.end.year, value.end.month - 1, value.end.day).toLocaleString(router.locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
      })
    : '';
  const valueInput = !startDate && !endDate ? '' : `${startDate} - ${endDate}`;
  const isMobile = device === DeviceEnum.MOBILE;
  const calendarType =
    localeConfigDatepicker.calendar === Persian
      ? languageJson.common.gregory_date
      : languageJson.common.jalali_date;
  const textFieldStyle = {
    '& > div': {
      width: '100%',
      '& > div': {
        '& > div': {
          backgroundColor: palette.white,
        },
      },
    },
    '& input': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      color: palette.black,
      height: '23px',
    },
    '& label': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: palette.gray,
    },
  };
  return (
    <Box position='relative'>
      <Dialog
        open={
          isMobile && isShowModalDatepicker
          // true
        }
        fullScreen
        onClose={() => setIsShowModalDatepicker(false)}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          p={3}
          sx={{
            borderBottom: `1px solid ${palette.gray}`,
            backgroundColor: palette.white,
          }}>
          <Stack
            direction='row'
            alignItems='center'>
            <Box
              component='span'
              className='icon-close'
              fontSize={28}
              onClick={() => setIsShowModalDatepicker(false)}></Box>
            <Typography variant='b1_bold'>{languageJson.common.select_dates}</Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            onClick={() =>
              setLocaleConfigDatepicker({
                ...localeConfigDatepicker,
                calendar: localeConfigDatepicker.calendar === Persian ? Gregory : Persian,
              })
            }>
            <Box
              fontSize={16}
              color={palette.primary}
              component='span'
              className='icon-calendar'></Box>
            <Typography
              variant='c1_bold'
              color='primary'>
              {calendarType}
            </Typography>
          </Stack>
        </Stack>
        <Box
          mt={0}
          py={3}
          px={2}
          color={palette.background}>
          <Box
            position='relative'
            width='100%'
            sx={textFieldStyle}>
            <TextFieldModified
              inputProps={{ readOnly: true }}
              onClick={() => setDatepickerMode('start')}
              value={valueInput}
              size='small'
              label={labelFrom || languageJson.common.departure_date}
              variant='outlined'
            />
            <Box
              component='span'
              className='icon-calendar'
              position='absolute'
              right={6}
              top={6}
              color={palette.gray}
              fontSize={28}></Box>
          </Box>
        </Box>
        <Box>
          <DatePicker
            onSelect={date => selectDatepickerRange(date)}
            calendar={localeConfigDatepicker.calendar}
            view={ScrollView}
            limit={limit}
            locale={localeConfigDatepicker.locale}
            dateRange={value}
          />
        </Box>
      </Dialog>
      <Box
        position='relative'
        width='100%'
        sx={textFieldStyle}
        ref={startInput}>
        <TextFieldModified
          focused={isShowModalDatepickerDesktop}
          inputProps={{ readOnly: true }}
          onClick={() => {
            setIsShowModalDatepickerDesktop(true);
            setIsShowModalDatepicker(true);
            setDatepickerMode('start');
          }}
          value={valueInput}
          size='small'
          label={labelFrom || languageJson.common.departure_date}
          variant='outlined'
        />
        <Box
          component='span'
          className='icon-calendar'
          position='absolute'
          right={12}
          top={6}
          color={palette.gray}
          fontSize={28}></Box>
      </Box>
      {isShowModalDatepickerDesktop && !isMobile && (
        <Box
          position='absolute'
          sx={{
            backgroundColor: palette.white,
            top: 48,
            border: `1px solid ${palette.gray}`,
            borderRadius: '6px',
            zIndex: 2,
          }}
          ref={datepickerDescktopRef}>
          <HeaderFooterCalendar
            hiddenFooter={hiddenFooter}
            localeConfigDatepicker={localeConfigDatepicker}
            setLocaleConfigDatepicker={setLocaleConfigDatepicker}
            hidden={() => setIsShowModalDatepickerDesktop(false)}
            isRange={true}
            value={value}
            startLabelFooter={languageJson.common.check_in}
            endLabelFooter={languageJson.common.check_out}>
            <DatePicker
              onSelect={date => selectDatepickerRange(date)}
              calendar={localeConfigDatepicker.calendar}
              view={DualView}
              limit={limit}
              locale={localeConfigDatepicker.locale}
              dateRange={value}
            />
          </HeaderFooterCalendar>
        </Box>
      )}
    </Box>
  );
}
