import { Box, Dialog, Stack, TextField, Typography } from '@mui/material';
import Persian from '@/components/common/date-picker/calendar/persian';
import DatePicker from '@/components/common/date-picker/date-picker';
import ScrollView from '@/components/common/date-picker/view/scroll-view';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import { useLanguage } from '@/components/common/language/language';
import type { MobileViewProps } from './mobile-view.types';
import palette from '@/components/common/styles/color.module.scss';

export default function MobileView(props: MobileViewProps) {
  const {
    value,
    limit,
    clearable,
    localeConfigDatepicker,
    setTripType,
    onSelect,
    closeDialog,
    changeTypeDate,
    datepickerMode,
    startDateValue,
    endDateValue,
    labelFrom,
    labelTo,
    isRange,
    setDatepickerMode,
  } = props;
  const device = useDevice();
  const languageJson = useLanguage();

  const isMobile = device === DeviceEnum.MOBILE;
  const textTypeDate =
    localeConfigDatepicker.calendar === Persian
      ? languageJson.common.gregory_date
      : languageJson.common.jalali_date;
  const textFieldStyle = {
    '& input': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      color: palette.black,
      height: '23px',
    },
    '& label': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: palette.gray,
    },
  };
  const iconStyle = {
    color: palette.gray,
    fontSize: 24,
    cursor: 'pointer',
  };
  return (
    <Dialog
      open={isMobile}
      fullScreen
      onClose={closeDialog}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        p={3}
        sx={{
          border: '1px solid ' + palette.gray,
          backgroundColor: palette.white,
        }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing='18px'>
          <Box
            component='span'
            fontSize={28}
            className='icon-close'
            onClick={closeDialog}></Box>
          <Typography
            variant='b1_bold'
            color='black'>
            {languageJson.common.select_dates}
          </Typography>
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          onClick={changeTypeDate}>
          <Box
            component='span'
            color={palette.primary}
            fontSize={16}
            className='icon-calendar'></Box>
          <Typography
            variant='c1_bold'
            color='primary'>
            {textTypeDate}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction='row'
        position='relative'
        alignItems='center'
        mt={0}
        py={3}
        px={2}
        sx={{
          backgroundColor: palette.background,
        }}>
        <Box
          position='relative'
          width='50%'>
          <TextField
            fullWidth
            sx={{
              ...textFieldStyle,
              '& fieldset': {
                borderRadius: '8px',
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                borderLeftWidth: '0px',
              },
            }}
            focused={datepickerMode === 'start'}
            onClick={() => setDatepickerMode('start')}
            value={startDateValue}
            size='small'
            id='outlined-basic'
            label={labelFrom || languageJson.common.departure_date}
            variant='outlined'
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Stack
                  direction='row'
                  alignItems='center'>
                  {clearable && startDateValue && datepickerMode === 'start' && (
                    <Box
                      component='span'
                      onClick={() => onSelect(undefined)}
                      sx={iconStyle}
                      className='icon-close'></Box>
                  )}
                  <Box
                    sx={iconStyle}
                    component='span'
                    className='icon-calendar'></Box>
                </Stack>
              ),
            }}
          />
        </Box>
        <Box
          position='relative'
          width='50%'
          height='100%'>
          {!isRange ? (
            <Stack
              direction='row'
              width='100%'
              height='100%'
              p='10px'
              spacing={1}
              sx={{
                background: palette.gray,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '0 8px 8px 0',
                cursor: 'pointer',
              }}
              onClick={() => setTripType?.()}>
              <Box
                color='white'
                component='span'
                className='icon-add'></Box>
              <Typography
                variant='c1_bold'
                color='white'>
                {languageJson.common.add_return_date}
              </Typography>
            </Stack>
          ) : (
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
                '& fieldset': {
                  borderRadius: '8px',
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                  borderLeftWidth: '0px',
                },
              }}
              focused={datepickerMode === 'end'}
              onClick={() => setDatepickerMode('end')}
              value={endDateValue}
              size='small'
              id='outlined-basic'
              label={labelTo || languageJson.common.return_date}
              variant='outlined'
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Stack
                    direction='row'
                    alignItems='center'>
                    {clearable && endDateValue && datepickerMode === 'end' && (
                      <Box
                        component='span'
                        onClick={() => onSelect(undefined)}
                        sx={iconStyle}
                        className='icon-close'></Box>
                    )}
                    <Box
                      sx={iconStyle}
                      component='span'
                      className='icon-calendar'></Box>
                  </Stack>
                ),
              }}
            />
          )}
        </Box>
      </Stack>
      <Box>
        <DatePicker
          onSelect={onSelect}
          calendar={localeConfigDatepicker.calendar}
          view={ScrollView}
          limit={limit}
          locale={localeConfigDatepicker.locale}
          date={!isRange ? value.start : undefined}
          dateRange={isRange ? value : undefined}
        />
      </Box>
    </Dialog>
  );
}
