import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TitleDescriptionV2 from './components/title-description-v2/title-description-v2';
import { useLanguage } from '../common/language/language';
import { type Departure } from '@/services';
import { JoinOurGroup } from './join-our-group';
import dynamic from 'next/dynamic';
import { ErrorBoundary } from '@sentry/nextjs';
import type { CmsLandingComponentsMarketingBanner } from '@travel-platform/commons/src/types';
import { useRouter } from 'next/router';
import type { wrapperCardSliderType } from '../common/slider-wrapper';

const AboutUs = dynamic(
  () => import(/* webpackChunkName: "AboutUs" */ '@/components/home/about-us/about-us')
);
const SliderWrapper = dynamic(
  () => import(/* webpackChunkName: "BestOffer" */ '../common/slider-wrapper')
);
const Blog = dynamic(() =>
  import(/* webpackChunkName: "Blog" */ '@/components/home/blog/blog').then(m => m.Blog)
);

const Tours = dynamic(() =>
  import(/* webpackChunkName: "Tours" */ '@/components/home/tours/tours').then(m => m.Tours)
);

const Reviews = dynamic(
  () => import(/* webpackChunkName: "Reviews" */ '@/components/home/reviews/reviews')
);
const Diaries = dynamic(
  () => import(/* webpackChunkName: "Diaries" */ '@/components/home/diaries/diaries')
);
const MostVisitedCitySection = dynamic(
  () =>
    import(
      /* webpackChunkName: "MostVisitedCitySection" */ '@/components/landings/most-visited-city-section/most-visited-city-section'
    )
);
const MarketingBanner = dynamic(
  () =>
    import(
      /* webpackChunkName: "MarketingBanner" */ '@/components/landings/marketing-banner/index'
    ),
  {
    ssr: true,
  }
);
const MostBookedFlightsSection = dynamic(
  () =>
    import(
      /* webpackChunkName: "MostBookedFlightsSection" */ '@/components/landings/most-booked-flights-section/most-booked-flights-section'
    )
);

type HomeComponentsProps = {
  departure?: Departure[];
  marketingBanner?: CmsLandingComponentsMarketingBanner;
  hotelSliderInfo?: wrapperCardSliderType;
};

export const HomeComponents: React.FC<HomeComponentsProps> = ({
  departure,
  marketingBanner,
  hotelSliderInfo,
}) => {
  const { common } = useLanguage();
  const router = useRouter();
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsShown(true);
    }, 50);
  }, []);

  return (
    <Stack
      spacing={{
        xs: 3,
        md: 8,
      }}>
      <ErrorBoundary
        fallback={
          <Typography
            textAlign={'center'}
            variant='b2_bold'>
            Marketings is not available.
          </Typography>
        }>
        {!!marketingBanner && marketingBanner.items.length && (
          <Stack spacing={{ xs: 2, md: 4 }}>
            <MarketingBanner items={marketingBanner.items} />
          </Stack>
        )}
      </ErrorBoundary>
      {isShown && (
        <>
          <ErrorBoundary
            fallback={
              <Typography
                textAlign={'center'}
                variant='b2_bold'>
                Departures is not available.
              </Typography>
            }>
            {!!departure && (departure?.length as number) > 0 && (
              <Stack spacing={{ xs: 2, md: 4 }}>
                <TitleDescriptionV2
                  size='sm'
                  title={common.join_jur_group_tours}
                  description={common.join_jur_group_tours_desc}
                />
                <JoinOurGroup departure={departure} />
              </Stack>
            )}
          </ErrorBoundary>
          <ErrorBoundary
            fallback={
              <Typography
                textAlign={'center'}
                variant='b2_bold'>
                Hotel best offers is not available.
              </Typography>
            }>
            {!!hotelSliderInfo && (
              <Stack spacing={{ xs: 2, md: 4 }}>
                <SliderWrapper {...hotelSliderInfo} />
              </Stack>
            )}
          </ErrorBoundary>
          <ErrorBoundary
            fallback={
              <Typography
                textAlign={'center'}
                variant='b2_bold'>
                Most visited flights is not available.
              </Typography>
            }>
            <Stack spacing={{ xs: 2, md: 4 }}>
              <MostBookedFlightsSection />
            </Stack>
          </ErrorBoundary>
          <ErrorBoundary
            fallback={
              <Typography
                textAlign={'center'}
                variant='b2_bold'>
                Tours is not available.
              </Typography>
            }>
            <Box>
              <Tours />
            </Box>
          </ErrorBoundary>
          <ErrorBoundary
            fallback={
              <Typography
                textAlign={'center'}
                variant='b2_bold'>
                Most visited cities is not available.
              </Typography>
            }>
            <Stack spacing={{ xs: 2, md: 4 }}>
              <MostVisitedCitySection
                showTitle={true}
                title={common.find_your_perfect_stay}
              />
            </Stack>
          </ErrorBoundary>
          <ErrorBoundary
            fallback={
              <Typography
                textAlign={'center'}
                variant='b2_bold'>
                Reviews is not available.
              </Typography>
            }>
            {router.locale === 'en' && (
              <Box>
                <Reviews />
              </Box>
            )}
          </ErrorBoundary>
          <Box>
            <ErrorBoundary
              fallback={
                <Typography
                  textAlign={'center'}
                  variant='b2_bold'>
                  Diaries is not available.
                </Typography>
              }>
              <Diaries />
            </ErrorBoundary>
          </Box>
          <ErrorBoundary
            fallback={
              <Typography
                textAlign={'center'}
                variant='b2_bold'>
                Blog is not available.
              </Typography>
            }>
            {router.locale === 'en' && (
              <Box>
                <Blog />
              </Box>
            )}
          </ErrorBoundary>
          <Box>
            <AboutUs />
          </Box>
        </>
      )}
    </Stack>
  );
};
