import ResponsiveSelect from '../responsive-select/responsive-select';
import type {
  Option,
  SelectComponentTotalProps,
} from '../responsive-select/responsive-select.types';
import SelectOption from './components/select-option/select-option';
import palette from '@/components/common/styles/color.module.scss';

export default function ResponsiveRadioSelect(props: SelectComponentTotalProps) {
  const {
    value,
    disabled,
    onChange,
    label,
    options,
    className,
    dialogLabel,
    selectLabel,
    icon,
    selectCustomWidth,
    renderValue,
    size,
  } = props;
  return (
    <ResponsiveSelect
      value={value}
      disabled={disabled}
      onChange={onChange}
      label={label}
      options={options}
      className={className}
      dialogLabel={dialogLabel}
      selectLabel={selectLabel}
      icon={icon}
      selectCustomWidth={selectCustomWidth}
      renderValue={renderValue}
      size={size}
      sx={{
        color: palette.black,
        backgroundColor: palette.white,
        '& > div': {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
          color: palette.black,
          webkitTextFillColor: palette.black,
        },
        '& fieldset': {
          borderRadius: '8px',
        },
      }}
      renderDrawerOptions={(options: Option[], setShowDrawer: (value: boolean) => void) => (
        <SelectOption
          setShowDrawer={setShowDrawer}
          options={options}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
}
