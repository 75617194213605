import ResponsiveSelect from '../responsive-select/responsive-select';
import { useLanguage } from '../language/language';
import { ButtonModified } from '../button-modified/button-modified';
import type { Option } from '../responsive-select/responsive-select.types';
import SelectOption from './components/select-option/select-option';
import type { ResponsiveCounterSelectProps } from './responsive-counter-select.types';
import { Box } from '@mui/material';
import palette from '@/components/common/styles/color.module.scss';
export default function ResponsiveCounterSelect(props: ResponsiveCounterSelectProps) {
  const { decreasable, increasable, searchData, value, onChange, options } = props;
  const languageJson = useLanguage();

  function renderDrawerOptions(options: Option[], setShowDrawer: (value: boolean) => void) {
    return (
      <Box>
        {options.map((passenger: Option, index) => (
          <SelectOption
            label={passenger.text.split('-')[0]}
            subLabel={passenger.text.split('-')[1]}
            count={searchData.passengers[passenger.value as keyof typeof searchData.passengers]}
            increasable={increasable(searchData, index)}
            decreasable={decreasable(searchData, index)}
            key={index}
            increase={() => onChange(passenger, 'increase')}
            decrease={() => onChange(passenger, 'decrease')}
          />
        ))}
        <Box mt={2}>
          <ButtonModified
            variant='contained'
            onClick={() => setShowDrawer(false)}
            fullWidth>
            {languageJson.common.confirm}
          </ButtonModified>
        </Box>
      </Box>
    );
  }
  function renderValue() {
    const items = options.map(passengerType => ({
      text: passengerType.value,
      value: searchData.passengers[passengerType.value as keyof typeof searchData.passengers],
    }));
    const sum = items.reduce(
      (prevValue, currentValue) => prevValue + parseInt(currentValue.value.toString()),
      0
    );
    return <>{sum + ' ' + languageJson.common.passenger}</>;
  }
  const customSelectOption = options.map((passenger, index) => (
    <SelectOption
      label={passenger.text.split('-')[0]}
      subLabel={passenger.text.split('-')[1]}
      count={searchData.passengers[passenger.value as keyof typeof searchData.passengers]}
      increasable={increasable(searchData, index)}
      decreasable={decreasable(searchData, index)}
      key={index}
      increase={() => onChange(passenger, 'increase')}
      decrease={() => onChange(passenger, 'decrease')}
    />
  ));
  return (
    <ResponsiveSelect
      sx={{
        color: palette.black,
        backgroundColor: palette.white,
        '& > div': {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
          color: palette.black,
          webkitTextFillColor: palette.black,
        },
        '& fieldset': {
          borderRadius: '8px',
        },
      }}
      value={value}
      label={languageJson.common.passengers}
      options={options}
      customSelectOption={customSelectOption}
      selectCustomWidth={300}
      renderValue={renderValue}
      renderDrawerOptions={renderDrawerOptions}
    />
  );
}
