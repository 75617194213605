import { Autocomplete, Box, Dialog, Paper, TextField, Typography, Stack } from '@mui/material';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import type { PlaceSearchResponse } from '@/services/places/place-search-translate.types';
import { apiSearchPlaces } from '@/services/places/places';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import useTimeout from '@/components/common/hooks/use-timeout';
import SpinnerLoading from '@/components/common/spinner-loading/spinner-loading';
import staticOptions from '@/components/home/hero-section/components/origin-destination/static-options.json';
import palette from '@/components/common/styles/color.module.scss';
import type {
  OriginDestinationObj,
  OriginDestinationProps,
} from '@/components/home/hero-section/components/origin-destination/origin-destination.types';
import { optionCreator } from '@/components/home/hero-section/components/origin-destination/functions';
import Option from '@/components/home/hero-section/components/origin-destination/components/option/option';
import { scrollBarStyle } from '@/components/common/styles/style';
export default function OriginDestination(props: OriginDestinationProps) {
  const { value, displacement, onChange, originInputLabel, destinationInputLabel } = props;
  const device = useDevice();
  const router = useRouter();
  const [handleSetTimeout, handleClearTimeout] = useTimeout();
  const [openDialog, setOpenDialog] = useState(false);
  const [originDestinationCurrent, setOriginDestinationCurrent] = useState({
    origin: value.origin?.label || '',
    destination: value.destination?.label || '',
  });
  const [mode, setMode] = useState(0);
  const [optionSelect, setOptionSelect] = useState<OriginDestinationObj[]>([]);
  const [searchTextPlace, setSearchTextPlace] = useState('');
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const originInput = useRef(null);
  const destinationInput = useRef(null);
  let cancelToken = useRef(axios.CancelToken.source());
  useEffect(() => {
    if (searchTextPlace) {
      setLoadingAutocomplete(true);
      handleClearTimeout(1000, getPlaces);
      handleSetTimeout(1000, getPlaces);
    } else {
      handleClearTimeout(1000, getPlaces); // stop searching previous value
      setLoadingAutocomplete(false); //stop loading
    }
  }, [searchTextPlace]);
  useEffect(() => {
    optionCreatorFunc(staticOptions as PlaceSearchResponse[]);
    window.addEventListener('click', clickOutsideOfSelectbox);
    return () => window.removeEventListener('click', clickOutsideOfSelectbox);
  }, [device]);
  useEffect(() => {
    setOriginDestinationCurrent({
      origin: value.origin?.label || '',
      destination: value.destination?.label || '',
    });
    optionCreatorFunc(staticOptions as PlaceSearchResponse[]);
  }, [value]);
  const clickOutsideOfSelectbox = (e: any) => {
    if (
      !isMobile &&
      !(
        e.composedPath().includes(originInput?.current) ||
        e.composedPath().includes(destinationInput?.current) ||
        openDialog
      )
    ) {
      setMode(0);
    }
  };
  const getPlaces = async () => {
    setLoadingAutocomplete(true);
    if (typeof cancelToken.current != typeof undefined) {
      cancelToken.current.cancel('Operation canceled due to new request.');
      cancelToken.current = axios.CancelToken.source();
    }
    try {
      const data = await apiSearchPlaces({ query: searchTextPlace }, cancelToken.current.token);
      optionCreatorFunc(data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAutocomplete(false);
    }
  };
  const optionCreatorFunc = (data: PlaceSearchResponse[]) => {
    const optionArray = optionCreator(data, router.locale || 'en', 'sub');
    setOptionSelect(optionArray);
  };
  const onChangeValue = (mode: 'origin' | 'destination', value: OriginDestinationObj | null) => {
    if (value) {
      onChange([value], [mode]);
      if (mode === 'origin') setMode(2);
      else setMode(0);
    } else {
      onChange([{ label: '', code: '', type: '', country: '' }], [mode]);
    }
  };
  const onChangeTextField = (mode: 'origin' | 'destination', value: string) => {
    setOriginDestinationCurrent({
      ...originDestinationCurrent,
      [mode]: value,
    });
    onChangeSearchText(mode, value);
  };
  const onCloseDialog = () => {
    setMode(0);
    setOriginDestinationCurrent({
      origin: value.origin.label,
      destination: value.destination.label,
    });
    setOpenDialog(false);
  };
  const onChangeSearchText = (mode: 'origin' | 'destination', value: string) => {
    setSearchTextPlace(value);
    if (!value) {
      optionCreatorFunc(staticOptions as PlaceSearchResponse[]); //show static options when searchText get empty
      onChangeValue(mode, null); //clear input when searchText get empty
    }
  };
  const onClickSearchText = (mode: 1 | 2, value: string, isOpenDialog: boolean = false) => {
    if (isOpenDialog) setOpenDialog(true);
    setMode(mode);
    if (mode === 1) {
      onChangeSearchText('origin', value);
    } else if (mode === 2) {
      onChangeSearchText('destination', value);
    }
  };
  const isMobile = device === DeviceEnum.MOBILE;
  const inpIcon = {
    top: '8px',
    color: palette.gray,
    fontSize: '24px',
    cursor: 'pointer',
    zIndex: '2',
    backgroundColor: palette.white,
    right: {
      xs: '64px',
      md: '8px',
    },
  };
  const fieldStyle = {
    '& div': {
      backgroundColor: palette.white,
      '& div': {
        '& div': {
          display: 'none',
        },
      },
    },
    label: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: palette.gray,
    },
    input: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: palette.black,
      width: 'calc(100% - 108px)',
      maxWidth: 'calc(100% - 108px)',
      webkitTextFillColor: palette.black + ' !important',
    },
    fieldset: {
      borderWidth: '1px !important',
      borderColor: palette.gray + ' !important',
    },
  };
  const paperAutoComplete = {
    width: {
      xs: '100%',
      md: 'calc(200% + 38px)',
    },
    position: {
      xs: 'static',
      md: 'relative',
    },
    '& ul': {
      padding: '0px',
      overflowX: 'hidden',
      marginLeft: '1px',
      ...scrollBarStyle,
    },
    '& .sub': {
      marginLeft: '24px',
    },
  };
  const optionLi = {
    '& > div': {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '-5px',
        height: '1px',
        backgroundColor: palette.gray_light_40,
      },
    },
  };
  return (
    <Box>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={onCloseDialog}>
        <Stack
          direction='row'
          alignItems='center'
          position='relative'
          sx={{
            p: 2,
            pb: 2.5,
            borderBottom: '1px solid' + palette.gray,
          }}>
          <Box
            component='span'
            className='icon-close'
            sx={{
              cursor: 'pointer',
            }}
            fontSize={28}
            onClick={onCloseDialog}></Box>
          <Typography
            variant='t3_bold'
            mt={2}
            color='black'>
            {originInputLabel}
          </Typography>
        </Stack>
        <Box
          sx={{
            backgroundColor: palette.background,
            py: 4,
            px: 2,
          }}>
          <Box position='relative'>
            <Box
              position='relative'
              width={{
                xs: 'auto',
                md: '50%',
              }}
              sx={{
                backgroundColor: palette.white,
              }}>
              <Box
                component='span'
                className='icon-flyDestination'
                position='absolute'
                sx={inpIcon}></Box>
              <TextField
                fullWidth
                inputRef={input => {
                  mode === 1 && input && input.focus();
                }}
                onClick={e => onClickSearchText(1, (e.target as HTMLInputElement).value)}
                onChange={e => onChangeTextField('origin', e.target.value)}
                size='small'
                value={originDestinationCurrent.origin}
                sx={{
                  ...fieldStyle,
                  '& div': {
                    pr: {
                      xs: 7,
                      md: 1,
                    },
                  },
                  '& fieldset': {
                    border: `1px solid ${palette.gray} !important`,
                    borderBottom: {
                      xs: '0 !important',
                      md: `1px solid ${palette.gray} !important`,
                    },
                    borderLeft: {
                      xs: '1px solid',
                      md: '0 !important',
                    },
                    borderRadius: {
                      xs: '6px 6px 0 0 !important',
                      md: '6px 0 0 6px !important',
                    },
                  },
                }}
                label={originInputLabel}
                variant='outlined'
              />
            </Box>
            <Box
              position='absolute'
              width='1px'
              sx={{
                backgroundColor: palette.gray,
                display: {
                  xs: 'block',
                  md: 'none',
                },
                top: 0,
                bottom: 0,
                right: '52px',
                zIndex: '1',
              }}></Box>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              sx={{
                height: {
                  xs: 'auto',
                  md: '37px',
                },
                p: {
                  xs: 0,
                  md: 0.5,
                },
                position: {
                  xs: 'absolute',
                  md: 'static',
                },
                border: {
                  xs: 0,
                  md: `1px solid ${palette.gray}`,
                },
                top: 0,
                right: '8px',
                bottom: 0,
              }}
              onClick={displacement}>
              <Box
                sx={{
                  backgroundColor: palette.white,
                  border: '1px solid ' + palette.primary,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: ' 10px',
                  zIndex: '1',
                  cursor: 'pointer',
                  transform: 'none',
                  width: {
                    xs: '36px',
                    md: '28px',
                  },
                  height: {
                    xs: '36px',
                    md: '28px',
                  },
                }}
                component='div'>
                <Box
                  sx={{
                    transform: 'rotate(90deg)',
                  }}
                  component='span'
                  color={palette.primary}
                  className='icon-arrow-go-come'></Box>
              </Box>
            </Stack>
            <Box
              position='relative'
              width={{
                xs: 'auto',
                md: '50%',
              }}
              sx={{
                backgroundColor: palette.white,
              }}>
              <Box
                component='span'
                className='icon-flyDestination'
                position='absolute'
                sx={inpIcon}></Box>
              <TextField
                fullWidth
                inputRef={input => {
                  mode === 2 && input && input.focus();
                }}
                onClick={e => onClickSearchText(2, (e.target as HTMLInputElement).value)}
                onChange={e => onChangeTextField('destination', e.target.value)}
                size='small'
                value={originDestinationCurrent.destination}
                sx={{
                  ...fieldStyle,
                  '& div': {
                    pr: {
                      xs: 7,
                      md: 1,
                    },
                  },
                  '& fieldset': {
                    border: `1px solid ${palette.gray} !important`,
                    borderLeft: {
                      xs: '1px solid',
                      md: '0 !important',
                    },
                    borderRadius: {
                      xs: '0 0 6px 6px !important',
                      md: '6px 0 0 6px !important',
                    },
                  },
                }}
                label={destinationInputLabel}
                variant='outlined'
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: palette.gray_light_40,
            padding: '16px',
            minHeight: 'calc(100vh - 206px)',
            maxHeight: ' calc(100vh - 206px)',
            overflowY: 'auto',
          }}>
          <Box>
            <Box
              sx={{
                '& sub': {
                  marginLeft: 3,
                },
              }}>
              {loadingAutocomplete ? (
                <SpinnerLoading size={24} />
              ) : (
                optionSelect.map((option, index) => (
                  <Box
                    position='relative'
                    mt={1}
                    sx={{
                      div: {
                        position: 'relative',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          left: 0,
                          right: 0,
                          bottom: '-5px',
                          height: '1px',
                          backgroundColor: palette.gray_light_40,
                        },
                      },
                    }}
                    key={index}
                    onClick={() => {
                      onChangeValue(mode === 1 ? 'origin' : 'destination', option);
                      setOriginDestinationCurrent({
                        ...originDestinationCurrent,
                        [mode === 1 ? 'origin' : 'destination']: option.label,
                      });
                      mode === 1 && setMode(2);
                      mode === 2 && setOpenDialog(false);
                      mode === 2 && setMode(0);
                    }}>
                    <Option option={option} />
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>

      <Box
        position='relative'
        sx={{
          display: {
            xs: 'block',
            md: 'flex',
          },
          '& input': {
            maxWidth: 'unset !important',
          },
        }}>
        <Box
          position='relative'
          width={{
            xs: 'auto',
            md: '50%',
          }}>
          <Box
            component='span'
            className='icon-fly-origin'
            position='absolute'
            sx={inpIcon}></Box>
          <Autocomplete
            fullWidth
            sx={fieldStyle}
            ref={originInput}
            filterOptions={options => options}
            readOnly={isMobile}
            loading={loadingAutocomplete}
            loadingText={<SpinnerLoading size={24} />}
            options={loadingAutocomplete ? [] : optionSelect}
            PaperComponent={props => (
              <Paper
                {...props}
                sx={{ ...paperAutoComplete }}
              />
            )}
            onChange={(e, value) => onChangeValue('origin', value)}
            getOptionLabel={option => option.label}
            value={value.origin}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderOption={(props, option) => (
              <Box
                {...props}
                className=''
                component='li'
                position='relative'
                mt={1}
                sx={optionLi}>
                <Option option={option} />
              </Box>
            )}
            renderInput={params => (
              <TextField
                {...params}
                inputRef={input => {
                  mode === 1 && input && !isMobile && input.focus();
                }}
                focused={!isMobile && mode === 1}
                onChange={e => onChangeTextField('origin', e.target.value)}
                onClick={e => onClickSearchText(1, (e.target as HTMLInputElement).value, isMobile)}
                size='small'
                sx={{
                  '& div': {
                    pr: {
                      xs: 7,
                      md: 1,
                    },
                  },
                  '& fieldset': {
                    border: `1px solid ${palette.gray} !important`,
                    borderBottom: {
                      xs: '0 !important',
                      md: `1px solid ${palette.gray} !important`,
                    },
                    borderRight: {
                      xs: '1px solid',
                      md: '0 !important',
                    },
                    borderRadius: {
                      xs: '6px 6px 0 0 !important',
                      md: '6px 0 0 6px !important',
                    },
                  },
                }}
                label={originInputLabel}
                variant='outlined'
              />
            )}
          />
        </Box>
        <Box
          position='absolute'
          width='1px'
          sx={{
            backgroundColor: palette.gray,
            display: {
              xs: 'block',
              md: 'none',
            },
            top: 0,
            bottom: 0,
            right: '52px',
            zIndex: '1',
          }}></Box>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{
            height: {
              xs: 'auto',
              md: '37px',
            },
            p: {
              xs: 0,
              md: 0.5,
            },
            position: {
              xs: 'absolute',
              md: 'static',
            },
            border: {
              xs: 0,
              md: `1px solid ${palette.gray}`,
            },
            top: 0,
            right: '8px',
            bottom: 0,
          }}
          onClick={displacement}>
          <Box
            sx={{
              backgroundColor: palette.white,
              border: '1px solid ' + palette.primary,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: ' 10px',
              zIndex: '1',
              cursor: 'pointer',
              transform: 'none',
              width: {
                xs: '36px',
                md: '28px',
              },
              height: {
                xs: '36px',
                md: '28px',
              },
            }}
            component='div'>
            <Box
              sx={{
                transform: 'rotate(90deg)',
              }}
              component='span'
              color={palette.primary}
              className='icon-arrow-go-come'></Box>
          </Box>
        </Stack>
        <Box
          position='relative'
          width={{
            xs: 'auto',
            md: '50%',
          }}>
          <Box
            component='span'
            className='icon-fly-destination'
            position='absolute'
            sx={inpIcon}></Box>
          <Autocomplete
            fullWidth
            sx={fieldStyle}
            ref={destinationInput}
            filterOptions={options => options}
            readOnly={isMobile}
            loading={loadingAutocomplete}
            loadingText={<SpinnerLoading size={24} />}
            options={loadingAutocomplete ? [] : optionSelect}
            PaperComponent={props => (
              <Paper
                {...props}
                sx={{
                  ...paperAutoComplete,
                  right: 'calc(100% + 38px)',
                }}
              />
            )}
            onChange={(e, value) => onChangeValue('destination', value)}
            value={value.destination}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={option => option.label}
            renderOption={(props, option) => (
              <Box
                {...props}
                component='li'
                className=''
                position='relative'
                mt={1}
                sx={optionLi}>
                <Option option={option} />
              </Box>
            )}
            renderInput={params => (
              <TextField
                {...params}
                inputRef={input => {
                  mode === 2 && input && !isMobile && input.focus();
                }}
                focused={!isMobile && mode === 2}
                onChange={e => onChangeTextField('destination', e.target.value)}
                onClick={e => onClickSearchText(2, (e.target as HTMLInputElement).value, isMobile)}
                size='small'
                sx={{
                  '& div': {
                    pr: {
                      xs: 7,
                      md: 1,
                    },
                  },
                  '& fieldset': {
                    border: `1px solid ${palette.gray} !important`,
                    borderLeft: {
                      xs: '1px solid',
                      md: '0 !important',
                    },
                    borderRadius: {
                      xs: '0 0 6px 6px !important',
                      md: '0 6px 6px 0 !important',
                    },
                  },
                }}
                label={destinationInputLabel}
                variant='outlined'
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}
