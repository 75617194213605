import ResponsiveAutocomplete from '@/components/common/responsive-autocomplete/responsive-autocomplete';
import type { AutocompleteOptions } from '@/components/common/responsive-autocomplete/view/autocomplete-view/autocomplete-view.types';
import DrawerOption from './components/drawer-option/drawer-option';
import type { SelectTravelStyleProps } from './select-travel-style.types';
import styles from './select-travel-style.module.scss';
import { type AutocompleteRenderOptionState, Checkbox, Typography } from '@mui/material';
import type { HTMLAttributes } from 'react';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useRouter } from 'next/router';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
export default function SelectTravelStyle(props: SelectTravelStyleProps) {
  const {
    options,
    helperText,
    error,
    sx,
    disabled,
    label,
    value,
    onChange,
    size,
    disableClearable,
  } = props;
  const device = useDevice();
  const router = useRouter();
  function renderAutocompleteOptions(
    props: HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOptions,
    state: AutocompleteRenderOptionState
  ) {
    return (
      <li {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={state.selected}
        />
        <Typography variant='b1_regular'>
          {router.locale === 'ar' ? option.ar_label : option.label}
        </Typography>
      </li>
    );
  }
  const isMobile = device === DeviceEnum.MOBILE;

  return (
    <ResponsiveAutocomplete
      disableCloseOnSelect
      limitTags={2}
      multiple={true}
      helperText={helperText}
      error={error}
      sx={sx}
      disableClearable={disableClearable}
      size={size}
      disabled={disabled || isMobile}
      label={label}
      value={value}
      getOptionLabel={option => (router.locale === 'ar' ? option.ar_label : option.label)!}
      onChange={(value: AutocompleteOptions | AutocompleteOptions[] | null) => {
        if (value)
          if (Array.isArray(value)) {
            onChange(value.map(val => val.value.toString()));
          } else {
            onChange(value.value.toString());
          }
      }}
      options={options}
      listBoxClass={styles.listbox}
      paperClass={styles.paperAutoComplete}
      renderAutocompleteOptions={renderAutocompleteOptions}
      renderDrawerOptions={(
        options: AutocompleteOptions[],
        setShowDrawer: (value: boolean) => void
      ) => (
        <DrawerOption
          multiple={true}
          options={options}
          label={label}
          value={value}
          setShowDrawer={setShowDrawer}
          onChange={onChange}
        />
      )}
    />
  );
}
