import { useLanguage } from '@/components/common/language/language';
import palette from '@/components/common/styles/color.module.scss';
import type { LandingServiceModuleCardHeaderItemProps } from '@/components/landings/landing.types';
import { Box, Stack, Typography, type SxProps, type Theme } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';

const LandingServiceModuleCardHeaderMobileItem: React.FC<
  LandingServiceModuleCardHeaderItemProps
> = props => {
  // Destructure Props
  const { title, isActive, name, icon, isNew } = props;

  // MUI Style
  const serviceCardStyle: SxProps<Theme> = {
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: isActive ? palette.primary : 'white',
    border: `1px solid ${palette.primary}`,
    borderRadius: 4,
    p: 1,
  };

  const router = useRouter();
  const languageJson = useLanguage();

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      onClick={() => router.push(`/${name}`)}
      width='82px'
      height='73px'
      sx={serviceCardStyle}>
      <Stack
        id='LandingServiceModuleCardHeaderItem'
        alignItems='center'
        width='100%'
        spacing={1.5}>
        {isNew && (
          <Stack
            position={'absolute'}
            sx={{
              backgroundColor: palette.secondary,
            }}
            borderRadius={2}
            p={0.5}
            right={'16px'}
            top={'6px'}
            color={palette.white}>
            <Typography variant='c3_bold'>{languageJson.common.new}</Typography>
          </Stack>
        )}
        <Box
          color={isActive ? palette.white : palette.primary}
          fontSize={20}
          className={icon}></Box>
        <Typography
          variant='c2_bold'
          color={isActive ? palette.white : 'primary'}>
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LandingServiceModuleCardHeaderMobileItem;
