import { type Calendar, CALENDARS, type CalendarName } from '.././calendar';
import { WeekDay } from '../type';

type Locale = {
  firstDayOfTheWeek: WeekDay;
  weekDays: Record<WeekDay, string>;
  calendars: {
    calendar: Calendar;
    months: string[];
  }[];
};

export function localeGenerator(translation: {
  days: string[];
  calendars: { calendar: string; months: string[] }[];
  firstDayOfTheWeek: number;
}): Locale {
  return {
    firstDayOfTheWeek: translation.firstDayOfTheWeek as WeekDay,
    weekDays: Object.fromEntries(
      translation.days.map((value, index) => [
        WeekDay[WeekDay[index] as keyof typeof WeekDay],
        value,
      ])
    ) as Record<WeekDay, string>,
    calendars: translation.calendars.map(value => ({
      calendar: CALENDARS[value.calendar as CalendarName],
      months: value.months,
    })),
  };
}

export default Locale;
