import { Box, Stack, Typography } from '@mui/material';
import Gregory from '@/components/common/date-picker/calendar/gregory';
import Persian from '@/components/common/date-picker/calendar/persian';
import { useRouter } from 'next/router';
import { useLanguage } from '@/components/common/language/language';
import { ButtonModified } from '@/components/common/button-modified/button-modified';
import palette from '@/components/common/styles/color.module.scss';
import type { HeaderFooterCalendarProps } from '@/components/common/date-picker/component/header-footer-calendar/header-footer-calendar.types';
export default function HeaderFooterCalendar(props: HeaderFooterCalendarProps) {
  const {
    value,
    children,
    hidden,
    localeConfigDatepicker,
    setLocaleConfigDatepicker,
    hiddenFooter,
    startLabelFooter,
    endLabelFooter,
  } = props;
  const router = useRouter();
  const languageJson = useLanguage();
  const changeConfigLocale = () => {
    setLocaleConfigDatepicker({
      ...localeConfigDatepicker,
      calendar: localeConfigDatepicker.calendar === Persian ? Gregory : Persian,
    });
  };
  const localeTypeText =
    localeConfigDatepicker.calendar === Persian
      ? languageJson.common.gregory_calendar
      : languageJson.common.jalali_calendar;
  const startText =
    value.start &&
    new Date(value.start.year, value.start.month - 1, value.start.day).toLocaleString(
      router.locale,
      {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
      }
    );
  const endText =
    value.end &&
    new Date(value.end.year, value.end.month - 1, value.end.day).toLocaleString(router.locale, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    });
  return (
    <Box>
      <Stack
        direction='row'
        alignItems='center'
        p={2}
        mx={2}
        justifyContent='space-between'
        sx={{
          direction: 'ltr',
          borderBottom: `1px solid ${palette.gray_light_40}`,
        }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{
            cursor: 'pointer',
          }}
          onClick={changeConfigLocale}>
          <Box
            component='span'
            className='icon-calendar'
            color='primary'
            fontSize={18}></Box>
          <Typography
            variant='c1_bold'
            color='primary'
            mr={1}>
            {localeTypeText}
          </Typography>
        </Stack>
      </Stack>
      {children}
      {!hiddenFooter && (
        <Stack
          direction='row'
          alignItems='center'
          p={2}
          mx={2}
          justifyContent='space-between'
          sx={{
            direction: 'ltr',
            borderTop: `1px solid ${palette.gray_light_40}`,
          }}>
          <Stack
            alignItems='center'
            direction='row'
            spacing={0.5}>
            {startText && (
              <Stack
                direction='row'
                alignItems='center'>
                <Typography
                  variant='b2_bold'
                  color='black'
                  mr={0.5}>
                  {startLabelFooter}:
                </Typography>
                <Typography
                  variant='b2_regular'
                  color='black'>
                  {startText}
                </Typography>
              </Stack>
            )}
            {endText && (
              <Stack
                direction='row'
                alignItems='center'>
                <Typography
                  variant='b2_bold'
                  color='black'
                  mr={0.5}>
                  {endLabelFooter}:
                </Typography>
                <Typography
                  variant='b2_regular'
                  color='black'>
                  {endText}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            sx={{
              cursor: 'pointer',
            }}>
            <ButtonModified
              onClick={() => hidden()}
              variant='contained'>
              {languageJson.common.confirm_date}
            </ButtonModified>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
