import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import type { LandingPageProps } from '@/components/landings/landing.types';
import LandingPageHeroSection from '@/components/landings/hero-section';
import { useLanguage } from '@/components/common/language/language';
import { ComponentType } from '@/services/CMS/cms.types';
import ScrollUp from '@/components/common/scroll-up';
import { CraftJourneyForm } from '@travel-platform/commons/src';
import { HomeComponents } from '../home/home-components';
import {
  type PopUpTourActionProps,
  PopUpTourWrapper,
} from '@/components/tours/page/components/popup';
import dynamic from 'next/dynamic';
import CountriesSlider from './country-slider';
import SliderWrapper from '../common/slider-wrapper';
import DebitCardTopUp from './debit-card-top-up';

const LandingPageAbout = dynamic(() => import('@/components/landings/about'), {
  ssr: true,
});
const RecentSearch = dynamic(() => import('./recent-search'), {
  ssr: false,
});
const LandingPageAlert = dynamic(() => import('@/components/landings/alert'), {
  ssr: true,
});
const FaqList = dynamic(() => import('@travel-platform/commons/src').then(comp => comp.FaqList), {
  ssr: true,
});
const LandingPageSteps = dynamic(() => import('@/components/landings/steps'), {
  ssr: true,
});
const LandingPageFaqsAttentionBox = dynamic(() => import('@/components/landings/attention-box'), {
  ssr: true,
});
const LandingPagePlanSelectBox = dynamic(() => import('@/components/landings/plan_select_box'), {
  ssr: true,
});
const LandingPageTable = dynamic(() => import('@/components/landings/table'), {
  ssr: true,
});
const LandingPageForm = dynamic(() => import('@/components/landings/form'), {
  ssr: false,
});
const LandingCarRentalCars = dynamic(() => import('./car-rental-cart'), {
  ssr: true,
});
const LandingInclusionCard = dynamic(
  () => import('./inclusion-card').then(comp => comp.LandingInclusionCard),
  {
    ssr: true,
  }
);
const LandingPageTravelServicePlan = dynamic(
  () => import('@/components/landings/travel-service-plan'),
  {
    ssr: true,
  }
);
const LandingMarketingBanner = dynamic(() => import('@/components/landings/marketing-banner'), {
  ssr: true,
});

function LandingPage({ landingData, departure, services, isLanding = true }: LandingPageProps) {
  // Destructure Props
  const { title, slug, description, thumbnail, components, has_travel_service_module } =
    landingData;

  const sortComp = components.sort((a, b) => a.priority - b.priority);

  const marketingBannerData = components?.find(
    item => item.type === ComponentType.MARKETING_BANNER
  )?.marketing_banner;

  const hotelSliderInfo = components?.find(
    item => item.type === ComponentType.SLIDER_CARD_COMPONENT
  )?.slider_card_component;

  // Use Hooks
  const languageJson = useLanguage();

  // Reference
  const popUpRef = React.useRef<FormsRef | any>();
  const submitForms = React.useRef<FormsRef | any>();

  // Constant
  const popUpData: PopUpTourActionProps = {
    title: languageJson.common.craft_my_journey,
    body: (
      <CraftJourneyForm
        inputType='both'
        defaultInputType='email'
        ref={submitForms}
        onClose={() => {
          popUpRef?.current?.closePopUp(false);
        }}
      />
    ),
    buttonText: languageJson.common.request_a_tour,
    buttonAction: () => {
      submitForms?.current?.onSubmit();
    },
  };

  return (
    <Box>
      <LandingPageHeroSection
        title={title}
        description={description}
        thumbnail={thumbnail}
        active={has_travel_service_module}
        slug={slug}
        services={services}
      />

      <Stack
        pt={{
          xs: 3,
          md: 8,
        }}
        spacing={{
          xs: 2,
          md: 4,
        }}>
        <PopUpTourWrapper
          ref={popUpRef}
          {...popUpData}
        />

        {slug === 'flight' && (
          <HomeComponents
            departure={departure}
            marketingBanner={marketingBannerData}
            hotelSliderInfo={hotelSliderInfo}
          />
        )}

        {slug === 'iran-travel-debit-card' && <DebitCardTopUp />}

        {isLanding &&
          sortComp.map((component, index) => (
            <React.Fragment key={index}>
              {component.type === ComponentType.ABOUT && <LandingPageAbout {...component.about} />}
              {component.type === ComponentType.SLIDER_CARD_COMPONENT && slug !== 'flight' && (
                <SliderWrapper {...component.slider_card_component} />
              )}
              {component.type === ComponentType.RECENT_SEARCH && <RecentSearch />}
              {component.type === ComponentType.COUNTRY_LIST && (
                <CountriesSlider
                  title={component.country_list.title}
                  items={component.country_list.items}
                />
              )}
              {component.type === ComponentType.CAR_PLANS && !!services && services.length > 0 && (
                <LandingCarRentalCars
                  services={services}
                  car_plans={component.car_plans}
                />
              )}
              {component.type === ComponentType.TRAVEL_SERVICE_PLAN &&
                !!services &&
                services.length > 0 && (
                  <LandingPageTravelServicePlan
                    {...component.travel_service_plan}
                    services={services}
                  />
                )}
              {component.type === ComponentType.INCLUSION_CART && (
                <LandingInclusionCard items={component.inclusion_carts} />
              )}
              {component.type === ComponentType.PLAN_PRICING_SELECT_BOX && (
                <LandingPagePlanSelectBox {...component.plan_select_box} />
              )}
              {component.type === ComponentType.ALERT && <LandingPageAlert {...component.alert} />}
              {component.type === ComponentType.STEPS && <LandingPageSteps {...component.step} />}
              {component.type === ComponentType.TABLE && <LandingPageTable {...component.table} />}
              {component.type === ComponentType.MARKETING_BANNER && slug !== 'flight' && (
                <LandingMarketingBanner items={component.marketing_banner.items} />
              )}
              {component.type === ComponentType.FORM && component.formActivity && (
                <LandingPageForm />
              )}
              {component.type === ComponentType.AT_BOX && (
                <LandingPageFaqsAttentionBox {...component.attentionBox} />
              )}
              {component.type === ComponentType.FAQS && (
                <Stack spacing={2}>
                  <Typography
                    component='h2'
                    sx={{
                      typography: {
                        xs: 'b2_bold',
                        md: 't2_bold',
                      },
                    }}>
                    {languageJson.common.faq}
                  </Typography>
                  <Stack spacing={2}>
                    <FaqList faqs={component.faqs} />
                  </Stack>
                </Stack>
              )}
            </React.Fragment>
          ))}
      </Stack>
      <ScrollUp />
    </Box>
  );
}

export default LandingPage;
