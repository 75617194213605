import { Box, Checkbox, MenuItem, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import styles from './drawer-option.module.scss';
import palette from '@/components/common/styles/color.module.scss';
import type { DrawerOptionProps } from './drawer-option.types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import type { AutocompleteOptions } from '@/components/common/responsive-autocomplete/view/autocomplete-view/autocomplete-view.types';
import { useRouter } from 'next/router';

const styleTextField = {
  '& .MuiOutlinedInput-input': {
    zIndex: '1',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '50px',
    background: palette.background,
    zIndex: '0',
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
export default function DrawerOption(props: DrawerOptionProps) {
  const { multiple, label, options, value, setShowDrawer, onChange } = props;
  const [searchText, setSearchText] = useState('');
  const isSelected = (option: AutocompleteOptions) => {
    const selected = multiple ? value.includes(option.value) : option.value === value;
    return selected;
  };
  const router = useRouter();

  return (
    <Box className={styles.content}>
      <Box className={styles.inp}>
        <TextField
          value={searchText}
          onChange={e => setSearchText((e.target as HTMLInputElement).value)}
          fullWidth
          size='small'
          label={label}
          sx={styleTextField}
        />
        <Box className={styles.icon}>
          <span className='icon-search'></span>
        </Box>
      </Box>
      <Box className={styles.options}>
        {options
          .filter(
            option =>
              option.label.toLowerCase().includes(searchText.toLowerCase()) ||
              option.ar_label?.includes(searchText.toLowerCase()) ||
              option.value.toString().toLowerCase().includes(searchText.toLowerCase())
          )
          .map((option, index) => (
            <MenuItem
              className={styles.option + ' ' + (isSelected(option) && styles.active)}
              key={index}
              onClick={() => {
                if (multiple && Array.isArray(value)) {
                  isSelected(option)
                    ? onChange(value.filter(val => val !== option.value))
                    : onChange([...value, option.value]);
                } else {
                  setShowDrawer(false);
                  onChange(option.value);
                  setSearchText('');
                }
              }}>
              <Stack
                width='100%'
                direction='row'
                alignItems='center'
                justifyContent='space-between'>
                <Box className={styles.label}>
                  {router.locale === 'ar' ? option.ar_label : option.label}
                </Box>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={isSelected(option)}
                />
              </Stack>
            </MenuItem>
          ))}
      </Box>
    </Box>
  );
}
