import { Stack, Typography } from '@mui/material';
import type { TitleDescriptionV2Props } from './title-description-v2.types';
import palette from '@/components/common/styles/color.module.scss';
import React from 'react';

export const TitleDescriptionV2: React.FC<TitleDescriptionV2Props> = props => {
  const { title, size, description } = props;
  const isSm = size === 'sm';
  return (
    <Stack
      alignItems={{
        xs: isSm ? 'unset' : 'center',
        md: 'unset',
      }}
      color={palette.primary}
      px={{ md: isSm ? 1 : 0 }}>
      <Typography
        sx={{
          typography: {
            xs: 't2_bold',
            md: 'h2_bold',
          },
        }}>
        {title}
      </Typography>
      {description && (
        <Typography
          px={{ md: isSm ? 0 : 3 }}
          mt={{
            xs: isSm ? '' : '-13px',
            md: isSm ? '' : '-20px',
          }}
          sx={{
            typography: {
              xs: 't3_regular',
              md: isSm ? 't1_regular' : 't1_bold',
            },
          }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default TitleDescriptionV2;
