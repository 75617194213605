import { Api, type AxiosReturnType, Method } from '../api';
import {
  type DebitCardTopUpResponseType,
  type DebitCardTopUpRequestType,
} from './debit-card.types';

export const apiDebitCardTopUp = (
  body: DebitCardTopUpRequestType,
  token?: string | null
): AxiosReturnType<DebitCardTopUpResponseType> => {
  return Api<DebitCardTopUpResponseType>(
    Method.POST,
    '/travel-service/debit-card/top-up',
    null,
    body,
    undefined,
    token
  );
};
