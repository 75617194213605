import React from 'react';
import DatePicker, { type CalendarProps, type DatePickerProps } from 'react-multi-date-picker';

export default function CustomMobileDatePicker({
  className,
  ...props
}: CalendarProps & DatePickerProps): React.ReactElement {
  return (
    <DatePicker
      fullYear
      className={`voiColor rmdp-mobile ${className}`}
      calendarPosition='bottom-center'
      {...props}
    />
  );
}
