import { Box, Stack, TextField, Typography } from '@mui/material';
import Gregory from '@/components/common/date-picker/calendar/gregory';
import Persian from '@/components/common/date-picker/calendar/persian';
import { useLanguage } from '@/components/common/language/language';
import { useRouter } from 'next/router';
import { isAfterDate, isBeforeDate } from '@/components/common/date-picker/functions';
import { useEffect, useRef, useState } from 'react';
import type { DateObject } from '@/components/common/date-picker/type';
import { localeGenerator } from '@/components/common/date-picker/locale';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import type {
  ModeDatepickerType,
  SelectDateProps,
} from '@/components/flights/search-fields-flights/components/select-date/select-date.types';
import type { ConfigCalendar } from '@/components/flights/search-fields-flights/search-fields-flights.types';
import MobileView from '@/components/flights/search-fields-flights/components/select-date/components/mobile-view/mobile-view';
import DesktopView from '@/components/flights/search-fields-flights/components/select-date/components/desktop-view/desktop-view';
import palette from '@/components/common/styles/color.module.scss';

export default function SelectDate(props: SelectDateProps) {
  const {
    value,
    onChange,
    isRange,
    clearable,
    setTripType,
    labelFrom,
    labelTo,
    hiddenFooter,
    limit,
  } = props;
  const device = useDevice();
  const languageJson = useLanguage();
  const router = useRouter();
  const [isShowDialogDatepicker, setIsShowDialogDatepicker] = useState(false);
  const [isShowDialogDatepickerDesktop, setIsShowDialogDatepickerDesktop] = useState(false);
  const [datepickerMode, setDatepickerMode] = useState<ModeDatepickerType>('start');
  const [localeConfigDatepicker, setLocaleConfigDatepicker] = useState<ConfigCalendar>({
    calendar: Gregory,
    locale: localeGenerator(languageJson.common.datepicker),
  });
  const datepickerDesktopRef = useRef<HTMLDivElement>(null);
  const startInput = useRef<HTMLDivElement>(null);
  const endInput = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setLocaleConfigDatepicker({
      ...localeConfigDatepicker,
      locale: localeGenerator(languageJson.common.datepicker),
    });
  }, [router.locale]);
  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, []);
  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, [isShowDialogDatepickerDesktop]);
  const clickHandler = (e: any) => {
    if (
      isShowDialogDatepickerDesktop &&
      !e.composedPath().includes(datepickerDesktopRef.current) &&
      !e.composedPath().includes(startInput.current) &&
      !e.composedPath().includes(endInput.current)
    ) {
      setIsShowDialogDatepickerDesktop(false);
    }
  };
  const selectDatepickerRange = (date?: DateObject) => {
    if (datepickerMode === 'start') {
      if (!date) {
        onChange('start', date);
      } else if (!value?.end || isBeforeDate(value.end, date)) {
        onChange('start', date);
        setDatepickerMode('end');
      } else {
        onChange('end', date);
        setDatepickerMode('start');
      }
    } else if (datepickerMode === 'end') {
      if (!date) {
        onChange('end', date);
      } else {
        if (value.start) {
          if (isAfterDate(value.start, date)) {
            onChange('end', date);
            setDatepickerMode('start');
          } else {
            onChange('start', date);
            setDatepickerMode('end');
          }
          setIsShowDialogDatepicker(false);
          setIsShowDialogDatepickerDesktop(false);
        } else {
          onChange('end', date);
          setDatepickerMode('start');
        }
      }
    }
  };
  const changeTypeDate = () => {
    setLocaleConfigDatepicker({
      ...localeConfigDatepicker,
      calendar: localeConfigDatepicker.calendar === Persian ? Gregory : Persian,
    });
  };
  const onSelect = (date?: DateObject) => {
    if (!isRange) {
      setIsShowDialogDatepicker(false);
      setIsShowDialogDatepickerDesktop(false);
      onChange('start', date);
    } else {
      selectDatepickerRange(date);
    }
  };
  const isMobile = device === DeviceEnum.MOBILE;
  const startDateValue = value?.start
    ? new Date(value.start.year, value.start.month - 1, value.start.day).toLocaleString(
        router.locale,
        {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        }
      )
    : '';
  const endDateValue = value.end
    ? new Date(value.end.year, value.end.month - 1, value.end.day).toLocaleString(router.locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
      })
    : '';
  const textFieldStyle = {
    '& input': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      color: palette.black,
      height: '23px',
    },
    '& label': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: palette.gray,
    },
  };
  const iconStyle = {
    color: palette.gray,
    fontSize: {
      xs: 20,
      md: 28,
    },
    cursor: 'pointer',
  };
  return (
    <>
      {isShowDialogDatepicker && (
        <MobileView
          clearable={clearable}
          closeDialog={() => setIsShowDialogDatepicker(false)}
          changeTypeDate={changeTypeDate}
          datepickerMode={datepickerMode}
          onSelect={onSelect}
          endDateValue={endDateValue}
          startDateValue={startDateValue}
          setDatepickerMode={setDatepickerMode}
          localeConfigDatepicker={localeConfigDatepicker}
          limit={limit}
          setTripType={setTripType}
          labelFrom={labelFrom}
          labelTo={labelTo}
          value={value}
          isRange={isRange}
        />
      )}
      <Box>
        <Stack
          direction='row'
          position='relative'
          alignItems='center'>
          <Box
            position='relative'
            width='50%'
            ref={startInput}>
            <TextField
              fullWidth
              sx={{
                ...textFieldStyle,
                '& fieldset': {
                  borderRadius: '8px',
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0,
                },
              }}
              focused={isShowDialogDatepickerDesktop && datepickerMode === 'start'}
              onClick={() => {
                setIsShowDialogDatepickerDesktop(true);
                setIsShowDialogDatepicker(true);
                setDatepickerMode('start');
              }}
              value={startDateValue}
              size='small'
              label={labelFrom || languageJson.common.departure_date}
              variant='outlined'
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Stack
                    direction='row'
                    alignItems='center'>
                    {clearable &&
                      startDateValue &&
                      isShowDialogDatepickerDesktop &&
                      datepickerMode === 'start' && (
                        <Box
                          component='span'
                          onClick={() => onSelect(undefined)}
                          sx={iconStyle}
                          className='icon-close'></Box>
                      )}
                    <Box
                      sx={iconStyle}
                      component='span'
                      className='icon-calendar'></Box>
                  </Stack>
                ),
              }}
            />
          </Box>
          <Box
            position='relative'
            width='50%'
            height='100%'
            ref={endInput}>
            {!isRange ? (
              <Stack
                direction='row'
                width='100%'
                height='100%'
                p='10px'
                spacing={1}
                sx={{
                  background: palette.gray,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '0 8px 8px 0',
                  cursor: 'pointer',
                }}
                onClick={() => setTripType?.()}>
                <Box
                  color='white'
                  component='span'
                  className='icon-add'></Box>
                <Typography
                  fontWeight={800}
                  fontSize={12}
                  lineHeight='20px'
                  variant='b1_bold'
                  color='white'>
                  {languageJson.common.add_return_date}
                </Typography>
              </Stack>
            ) : (
              <Box>
                <TextField
                  fullWidth
                  sx={{
                    ...textFieldStyle,
                    '& fieldset': {
                      borderRadius: '8px',
                      borderBottomLeftRadius: 0,
                      borderTopLeftRadius: 0,
                      borderLeftWidth: '0px',
                    },
                  }}
                  focused={isShowDialogDatepickerDesktop && datepickerMode === 'end'}
                  onClick={() => {
                    setIsShowDialogDatepickerDesktop(true);
                    setIsShowDialogDatepicker(true);
                    setDatepickerMode('end');
                  }}
                  value={endDateValue}
                  size='small'
                  label={labelTo || languageJson.common.return_date}
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Stack
                        direction='row'
                        alignItems='center'>
                        {clearable &&
                          endDateValue &&
                          isShowDialogDatepickerDesktop &&
                          datepickerMode === 'end' && (
                            <Box
                              component='span'
                              onClick={() => onSelect(undefined)}
                              sx={iconStyle}
                              className='icon-close'></Box>
                          )}
                        <Box
                          sx={iconStyle}
                          component='span'
                          className='icon-calendar'></Box>
                      </Stack>
                    ),
                  }}
                />
              </Box>
            )}
          </Box>
          {isShowDialogDatepickerDesktop && !isMobile && (
            <DesktopView
              hiddenFooter={hiddenFooter}
              limit={limit}
              isRange={isRange}
              value={value}
              localeConfigDatepicker={localeConfigDatepicker}
              datepickerDesktopRef={datepickerDesktopRef}
              closeShowDialogDatepickerDesktop={() => setIsShowDialogDatepickerDesktop(false)}
              setLocaleConfigDatepicker={setLocaleConfigDatepicker}
              onSelect={onSelect}
            />
          )}
        </Stack>
      </Box>
    </>
  );
}
