import { apiTranslatePlaceCode } from '@/services/places/places';
import type {
  AirlineTranslateResponse,
  PlaceTranslationResponse,
} from '@/services/places/place-search-translate.types';

export const translateAirlineCode = (
  code: string,
  locale: string | undefined,
  airlineCodesInfo: AirlineTranslateResponse[] | null
): string => {
  const desireCode = airlineCodesInfo?.find(codeInfo => codeInfo.code === code);
  return desireCode?.airline_names.find(name => name.language === locale)?.value || '';
};
export const translateAirportCode = (
  code: string,
  translateField: 'airport' | 'city' | 'country',
  locale: string | undefined,
  airportCodesInfo: PlaceTranslationResponse[] | null
): string => {
  const desireCode = airportCodesInfo?.find(codeInfo => codeInfo.code === code);
  if (translateField)
    switch (translateField) {
      case 'airport':
        return desireCode?.airport_names.find(name => name.language === locale)?.value || '';
      case 'city':
        return desireCode?.city.city_names.find(name => name.language === locale)?.value || '';
      case 'country':
        return (
          desireCode?.city.country.country_names.find(name => name.language === locale)?.value || ''
        );
      default:
        return '';
    }
  else return '';
};
export const timeConverter = (duration: number, languageJson: any): string => {
  const hour = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hour} ${languageJson.common.hour} ${languageJson.common.and} ${minutes} ${languageJson.common.minute}`;
};

export const getAirportInformation = async (airportCodes: { airportCodes: string[] }) => {
  if (airportCodes.airportCodes.length > 0) {
    try {
      const data = await apiTranslatePlaceCode(airportCodes);
      return data.data;
    } catch (err) {
      console.log(err);
    }
  }
};
export const getNewCodes = (
  codes: string[],
  storedCode: PlaceTranslationResponse[] | AirlineTranslateResponse[]
): string[] => {
  const extractCode = storedCode.map(item => item.code);
  return codes
    .concat(extractCode)
    .filter(code => codes.includes(code) && !extractCode.includes(code));
};
