import type { DateObject, WeekDay } from '../type';
import Gregory from './gregory';
import Persian from './persian';

export type Calendar = {
  getMonth: (year: number, month: number) => { firstDayWeekDay: WeekDay; days: number };
  monthCount: 12;

  toLocalDateObject(date: DateObject): DateObject;
  toGregoryDateObject(date: DateObject): DateObject;
};

export const CALENDARS = {
  persian: Persian,
  gregory: Gregory,
};

export type CalendarName = keyof typeof CALENDARS;
