import React from 'react';
import { Stack } from '@mui/material';
import type { LandingPageServicesModuleProps } from '@/components/landings/landing.types';
import palette from '@/components/common/styles/color.module.scss';
import SearchFieldsFlights from '@/components/flights/search-fields-flights/search-fields-flights';
import SearchFieldsHotels from '@/components/hotels/search-fields-hotels/search-fields-hotels';
import { LandingServiceModuleTour } from './tours-service';
import LandingServiceModuleCardHeader from '@/components/landings/hero-section/service-component/landing-service-module-card-header';
import LandingServiceModuleCardVariant from '@/components/landings/hero-section/service-component/landing-service-module-card-variant';
// import { LandingServiceModuleTour } from '@/components/landings/hero-section/service-component/tours-service';
// import SearchFieldsHotels from '@/components/hotels/search-fields-hotels/search-fields-hotels';
// import SearchFieldsFlights from '@/components/flights/search-fields-flights/search-fields-flights';

const LandingPageServicesModule = ({ service, services, slug }: LandingPageServicesModuleProps) => {
  // constant
  const staticService: Record<string, any> = {
    tours: <LandingServiceModuleTour />,
    flight: <SearchFieldsFlights />,
    hotel: <SearchFieldsHotels />,
  };

  return (
    <React.Fragment>
      <Stack
        position='relative'
        alignItems={{ xs: 'center', md: 'unset' }}
        spacing={6}>
        <LandingServiceModuleCardHeader
          services={services}
          service={service}
        />
      </Stack>
      <Stack
        position='relative'
        spacing={6}
        border={`1px solid ${palette.primary}`}
        pt={2.5}
        p={2}
        px={{ md: 4 }}
        borderRadius={{
          xs: 4,
          md: '0 0 16px 16px',
        }}
        sx={{
          backgroundColor: palette.white,
        }}>
        {!!staticService[slug] ? (
          staticService[slug]
        ) : (
          <LandingServiceModuleCardVariant service={service} />
        )}
      </Stack>
    </React.Fragment>
  );
};

export default LandingPageServicesModule;
