import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { ButtonModified } from '../../../button-modified/button-modified';
import { useLanguage } from '../../../language/language';
import type { SelectOptionProps } from './select-option.types';

export default function SelectOption(props: SelectOptionProps) {
  const { value, options, onChange, setShowDrawer } = props;
  const languageJson = useLanguage();
  return (
    <FormControl
      fullWidth
      onChange={e => onChange?.((e.target as HTMLInputElement).value)}>
      <RadioGroup
        name='radio-buttons-group'
        value={value}>
        <Stack
          mt={2}
          mb={3}>
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={option.text}
            />
          ))}
        </Stack>
        <Box>
          <ButtonModified
            fullWidth
            variant='contained'
            onClick={() => setShowDrawer(false)}>
            {languageJson.common.confirm}
          </ButtonModified>
        </Box>
      </RadioGroup>
    </FormControl>
  );
}
