import { useLanguage } from '@/components/common/language/language';
import palette from '@/components/common/styles/color.module.scss';
import type { LandingServiceModuleCardHeaderItemProps } from '@/components/landings/landing.types';
import { Box, Stack, Typography, type SxProps, type Theme } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';

// Mui Style
const serviceCardStyle: SxProps<Theme> = {
  position: 'relative',
  cursor: 'pointer',
  pb: 2,
};

const LandingServiceModuleCardHeaderDesktopItem: React.FC<
  LandingServiceModuleCardHeaderItemProps
> = props => {
  // Destructure Props
  const { title, isActive, name, icon, isNew } = props;
  const languageJson = useLanguage();
  // Use Hooks
  const router = useRouter();

  return (
    <Stack
      position='relative'
      onClick={() => router.push(`/${name}`)}
      sx={serviceCardStyle}>
      {isActive && (
        <Box
          borderRadius='2px'
          position='absolute'
          bottom={0}
          right='50%'
          width='100%'
          border={`4px solid ${palette.primary}`}
          sx={{
            transform: 'translateX(50%)',
          }}></Box>
      )}

      <Stack
        id='LandingServiceModuleCardHeaderItem'
        direction='row'
        alignItems='center'
        width='100%'
        spacing={1}>
        {isNew && (
          <Stack
            position={'absolute'}
            sx={{
              backgroundColor: palette.secondary,
            }}
            borderRadius={2}
            p={0.5}
            left={'-8px'}
            top={'-5px'}
            color={palette.white}>
            <Typography variant='c3_bold'>{languageJson.common.new}</Typography>
          </Stack>
        )}
        <Box
          color={isActive ? palette.primary : 'black'}
          fontSize={18}
          className={icon}></Box>

        <Typography
          sx={{
            typography: {
              xs: 'c2_regular',
              md: 't3_bold',
            },
          }}
          color={isActive ? 'primary' : ''}>
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LandingServiceModuleCardHeaderDesktopItem;
