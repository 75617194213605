import { Box, Button, Typography } from '@mui/material';
import DebitCard from '@/components/common/images/debit-card.svg';
import { useLanguage } from '@/components/common/language/language';
import palette from '@/components/common/styles/color.module.scss';
import { useRef, useState } from 'react';
import {
  PopUpTourWrapper,
  type PopUpTourActionProps,
} from '@/components/tours/page/components/popup';
import DebitCardTopUpForm from './form';
import Image from '@/components/common/image/image';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/redux';
import { loginSetFirstStep, loginShowDialog } from '@travel-platform/commons/src/redux/login-slice';

const DebitCardTopUp = () => {
  const languageJson = useLanguage();
  const dispatch = useDispatch();
  const { auth } = useAppSelector(state => state.auth);

  const popUpRef = useRef<FormsRef | any>();
  // States
  const [popUpData, setPopUpData] = useState<PopUpTourActionProps>({
    title: '',
    body: <></>,
  });

  // Functions
  const openTopUpCardHandler = () => {
    if (!auth?.token) {
      dispatch(loginShowDialog(true));
      dispatch(loginSetFirstStep('PhoneLogin'));
    } else {
      setPopUpData({
        title: languageJson.common.debit_card_top_up,
        body: <DebitCardTopUpForm />,
      });
      popUpRef?.current?.closePopUp(true);
    }
  };

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      gap={{
        xs: '16px',
        md: '64px',
      }}
      padding={{
        xs: '32px',
        md: '64px',
      }}
      border={`1px solid ${palette.gray_light_40}`}
      borderRadius='15px'>
      <Box>
        <Image
          src={DebitCard}
          width={{
            xs: 264,
            md: 408,
          }}
          height={230}
          alt='debit card image'
          style={{ borderRadius: '15px', width: '100%', height: 'auto' }}
        />
      </Box>
      <Box
        width={{
          xs: '100%',
          md: '50%',
        }}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <Typography
          fontWeight='700'
          fontSize={{ xs: '18px', md: '32px' }}
          lineHeight={{ xs: '32px', md: '56px' }}>
          {languageJson.common.top_up_title}
        </Typography>
        <Typography
          textAlign='center'
          fontSize={{ xs: '16px', md: '24px' }}
          lineHeight={{ xs: '28px', md: '40px' }}>
          {languageJson.common.top_up_desc}
        </Typography>
        <Button
          variant='contained'
          sx={{
            height: {
              xs: '36px',
              md: '48px',
            },
            width: {
              xs: '206px',
              md: '284px',
            },
            marginTop: '16px',
            boxShadow: 'none',
            borderRadius: '8px',
          }}
          onClick={() => openTopUpCardHandler()}>
          {languageJson.common.top_up_now}
        </Button>
      </Box>
      <PopUpTourWrapper
        ref={popUpRef}
        {...popUpData}
      />
    </Box>
  );
};

export default DebitCardTopUp;
