export const optionsYearChild = (
  languageJson: any
): {
  value: number;
  label: any;
}[] => {
  const options = new Array(12).fill(1).map((item, index) =>
    index === 0
      ? {
          value: index,
          label: languageJson.common.less_than_one_year,
        }
      : {
          value: index,
          label: `${index} - ${index + 1} ${languageJson.common.year}`,
        }
  );
  return options;
};
