import type { CmsLandings } from '@/services/CMS/cms.types';
import type { FC } from 'react';

const JsonLdPage: FC<{ landingData: CmsLandings }> = ({ landingData }) => {
  const { slug, title, description, thumbnail } = landingData;

  const isService = slug !== 'flight' && slug !== 'hotel' && slug !== 'tours';

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': isService ? 'Service' : 'WebPage',
    ...(isService && { serviceType: title }),
    name: title,
    image: thumbnail?.src,
    description: description,
    aggregateRating: {
      '@type': 'AggregateRating',
      bestRating: '5',
      ratingValue: '5',
      reviewCount: '76',
    },
    provider: {
      '@type': 'Organization',
      url: 'https://www.visitouriran.com',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: process.env.NEXT_PUBLIC_WHATSAPP_VISIT,
          contactType: 'customer service',
        },
      ],
    },
    termsOfService: 'https://www.visitouriran.com/blog/privacy-policy/',
  };

  return (
    <>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
    </>
  );
};
export default JsonLdPage;
