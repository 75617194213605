import { Box, Select } from '@mui/material';
import type { ReactElement } from 'react';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import type { SelectViewProps } from './select-view.types';
import palette from '@/components/common/styles/color.module.scss';

export default function SelectView(props: SelectViewProps) {
  const {
    sx,
    value,
    paperSx,
    disabled,
    multiple,
    onClose,
    onOpen,
    open,
    onChange,
    className,
    label,
    selectCustomWidth,
    renderValue,
    size,
    setShowDrawer,
    icon,
    iconLess,
    children,
  } = props;
  const device = useDevice();
  const iconComponent: ReactElement = iconLess ? (
    <> </>
  ) : (
    icon || (
      <Box
        component='span'
        mx={1}
        className='icon-chevron-light-down'></Box>
    )
  );
  const isMobile = device === DeviceEnum.MOBILE;
  const selectStyle = {
    ...sx,
    '& .Mui-disabled': {
      WebkitTextFillColor: `${palette.black} !important`,
    },
    '&.Mui-error fieldset': {
      borderColor: '#d32f2f !important',
    },
  };
  const menuPropsStyle = {
    PaperProps: {
      sx: {
        border: `1px solid ${palette.gray}`,
        borderRadius: '6px',
        marginTop: '4px',
        boxShadow: '0 0 0 0',
        minWidth: selectCustomWidth && selectCustomWidth + 'px !important',
        '& .MuiList-root': {
          padding: '0',
        },
        '& .MuiMenuItem-root:hover': {
          backgroundColor: `${palette.primary + '33'} !important`,
        },
        ...paperSx,
      },
    },
  };
  return (
    <Select
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      size={size}
      className={className}
      value={value}
      label={label}
      onChange={e => onChange?.(e.target.value)}
      onClick={() => {
        isMobile && !disabled && setShowDrawer();
      }}
      IconComponent={() => iconComponent}
      sx={selectStyle}
      renderValue={renderValue}
      MenuProps={menuPropsStyle}
      multiple={multiple}>
      {children}
    </Select>
  );
}
