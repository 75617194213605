import type { DateObject } from '../type';
import type { Calendar } from '.';

export function isLeapYear(year: number) {
  return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
}

type MonthDays = (year: number) => number;

const monthDays: MonthDays[] = [
  () => 31,
  (year: number) => (isLeapYear(year) ? 29 : 28),
  () => 31,
  () => 30,
  () => 31,
  () => 30,
  () => 31,
  () => 31,
  () => 30,
  () => 31,
  () => 30,
  () => 31,
];

const Gregory: Calendar = {
  monthCount: 12,

  getMonth(year: number, month: number) {
    const date = new Date(year, month - 1, 1);

    return {
      days: monthDays[month - 1](year),
      firstDayWeekDay: date.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6,
    };
  },

  toLocalDateObject(date: DateObject) {
    return date;
  },

  toGregoryDateObject(date: DateObject) {
    return date;
  },
};

export default Gregory;
