import type { Calendar } from '././calendar';
import type { DateObject, DateRangeObject } from './type';
import ViewManager, { type ViewComponent } from './view-manager';
import type Locale from './locale';

type DatePickerProps = {
  view: ViewComponent;
  calendar: Calendar;
  locale: Locale;

  date?: DateObject;
  dateRange?: Partial<DateRangeObject>;

  limit?: DateRangeObject;

  onSelect?(date: DateObject): void;
};

export default function DatePicker(props: DatePickerProps) {
  const { view, locale, calendar, date, dateRange, limit, onSelect } = props;

  if (date && dateRange) {
    throw new Error('date and dateRange cannot be set simultaneously.');
  }

  return (
    <ViewManager
      view={view}
      locale={locale}
      calendar={calendar}
      date={date}
      dateRange={dateRange}
      limit={limit}
      onSelect={onSelect}
    />
  );
}
