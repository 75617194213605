import { Box } from '@mui/material';
import HeaderFooterCalendar from '@/components/common/date-picker/component/header-footer-calendar/header-footer-calendar';
import DatePicker from '@/components/common/date-picker/date-picker';
import DualView from '@/components/common/date-picker/view/dual-view';
import { useLanguage } from '@/components/common/language/language';
import type { DesktopViewProps } from '@/components/flights/search-fields-flights/components/select-date/components/desktop-view/desktop-view.types';
import palette from '@/components/common/styles/color.module.scss';
export default function DesktopView(props: DesktopViewProps) {
  const {
    hiddenFooter,
    localeConfigDatepicker,
    setLocaleConfigDatepicker,
    closeShowDialogDatepickerDesktop,
    isRange,
    value,
    limit,
    onSelect,
    datepickerDesktopRef,
  } = props;
  const languageJson = useLanguage();
  return (
    <Box
      position='absolute'
      top={48}
      width='100%'
      sx={{
        backgroundColor: palette.white,
        border: '1px solid' + palette.gray,
        borderRadius: 2,
        zIndex: 2,
      }}
      ref={datepickerDesktopRef}>
      <Box>
        <HeaderFooterCalendar
          hiddenFooter={hiddenFooter}
          localeConfigDatepicker={localeConfigDatepicker}
          setLocaleConfigDatepicker={setLocaleConfigDatepicker}
          hidden={closeShowDialogDatepickerDesktop}
          isRange={isRange}
          value={value}
          startLabelFooter={languageJson.common.departure}
          endLabelFooter={languageJson.common.return}>
          <DatePicker
            onSelect={onSelect}
            calendar={localeConfigDatepicker.calendar}
            view={DualView}
            limit={limit}
            locale={localeConfigDatepicker.locale}
            date={!isRange ? value.start : undefined}
            dateRange={isRange ? value : undefined}
          />
        </HeaderFooterCalendar>
      </Box>
    </Box>
  );
}
