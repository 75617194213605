import { Autocomplete, Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { useLanguage } from '@/components/common/language/language';
import SelectOptionCounter from '@/components/common/responsive-counter-select/components/select-option/select-option';
import type { Option } from '@/components/common/responsive-select/responsive-select.types';
import palette from '@/components/common/styles/color.module.scss';
import type { SelectOptionProps } from '@/components/hotels/search-fields-hotels/components/responsive-room-passenger-select/components/select-option/select-option.types';
import { optionsYearChild } from '@/components/hotels/search-fields-hotels/components/responsive-room-passenger-select/components/select-option/functions';
const styleSelectAgeChildAutoComplete = {
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: palette.black,
  },
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#d32f2f !important',
  },
  '& p.Mui-error': {
    marginRight: '14px',
    marginLeft: '0px',
  },
};

export default function SelectOption(props: SelectOptionProps) {
  const {
    room,
    indexRoom,
    roomPassenger,
    options,
    removeRoom,
    updateAges,
    updatePassengerCountOfRoom,
    isDisabledDecreasePassengerCounter,
    isDisabledIncreasePassengerCounter,
  } = props;
  const languageJson = useLanguage();
  const deletableRoom = roomPassenger.length !== 1;
  function valueSelectAgeChildAutoComplete(passenger: Option, indexItem: number) {
    return {
      value: room.passengers[passenger.value as keyof typeof room.passengers].ages[indexItem],
      label:
        optionsYearChild(languageJson).find(
          item =>
            item.value ===
            room.passengers[passenger.value as keyof typeof room.passengers].ages[indexItem]
        )?.label || '',
    };
  }
  function isOptionEqualToValue(
    option: { value: number; label: string },
    passenger: Option,
    indexItem: number
  ) {
    return (
      option.value ===
      room.passengers[passenger.value as keyof typeof room.passengers].ages[indexItem]
    );
  }
  return (
    <Box mb={6}>
      <Stack
        direction='row'
        alignItems='center'>
        <Typography variant='t2_bold'>
          {languageJson.common.room} {indexRoom + 1}
        </Typography>
        <Box
          flexGrow={1}
          height='1px'
          mx={2}
          sx={{
            backgroundColor: palette.gray_light_40,
          }}></Box>
        {deletableRoom && (
          <Stack
            direction='row'
            alignItems='center'
            color={palette.error}
            spacing={1}
            onClick={() => removeRoom(indexRoom)}>
            <Box
              component='span'
              fontSize={20}
              className='icon-trash'></Box>
            <Typography variant='b2_bold'>{languageJson.common.delete}</Typography>
          </Stack>
        )}
      </Stack>
      <Box>
        {options.map((passenger, indexPassenger) => (
          <SelectOptionCounter
            key={indexPassenger}
            label={passenger.text.split('-')[0]}
            subLabel={passenger.text.split('-')[1]}
            count={room.passengers[passenger.value as keyof typeof room.passengers].value}
            increase={() => updatePassengerCountOfRoom(passenger.value, indexRoom, 'increase')}
            decrease={() => updatePassengerCountOfRoom(passenger.value, indexRoom, 'decrease')}
            decreasable={isDisabledDecreasePassengerCounter(indexRoom, indexPassenger)}
            increasable={isDisabledIncreasePassengerCounter(indexRoom, indexPassenger)}
          />
        ))}
      </Box>
      {options.map(
        (passenger, indexPassenger) =>
          room.passengers[passenger.value as keyof typeof room.passengers].askAges && (
            <Grid
              container
              spacing={2}
              key={indexPassenger}>
              {new Array(room.passengers[passenger.value as keyof typeof room.passengers].value)
                .fill(' ')
                .map((item, indexItem) => (
                  <Grid
                    item
                    xs={6}
                    key={indexItem}>
                    <Autocomplete
                      sx={styleSelectAgeChildAutoComplete}
                      value={valueSelectAgeChildAutoComplete(passenger, indexItem)}
                      isOptionEqualToValue={option =>
                        isOptionEqualToValue(option, passenger, indexItem)
                      }
                      disableClearable
                      size='small'
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={languageJson.common.child_age + (indexItem + 1)}
                        />
                      )}
                      options={optionsYearChild(languageJson)}
                      onChange={(event, value) =>
                        updateAges(indexRoom, passenger.value, value.value, indexItem)
                      }
                    />
                    {(room.passengers[passenger.value as keyof typeof room.passengers].ages[
                      indexItem
                    ] === null ||
                      room.passengers[passenger.value as keyof typeof room.passengers].ages[
                        indexItem
                      ] === undefined) && (
                      <Stack
                        direction='row'
                        alignItems='center'
                        mt={0.5}
                        color={palette.error}>
                        <Box
                          component='span'
                          mr={0.5}
                          className='icon-danger'></Box>
                        <Typography variant='c2_bold'>
                          {languageJson.common.child_age_required}!
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                ))}
            </Grid>
          )
      )}
    </Box>
  );
}
