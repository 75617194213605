import { Carousel } from '@/components/common/carousel';
import { Stack, Typography } from '@mui/material';
import type { CmsLandingComponentsSliderItem } from '@travel-platform/commons/src/types';
import CountrySliderItem from './country';

export type CountriesSliderType = {
  title: string;
  subTitle?: string;
  slidesPerView?: number;
  items: CmsLandingComponentsSliderItem[];
};

const CountriesSlider = (props: CountriesSliderType) => {
  const { title, subTitle, slidesPerView, items } = props;

  return (
    <>
      <Stack>
        {title && (
          <Typography
            sx={{
              typography: {
                xs: 't1_bold',
                md: 'h3_bold',
              },
            }}>
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography
            mb={2}
            variant='t2_regular'>
            {subTitle}
          </Typography>
        )}
      </Stack>
      <Carousel
        loop
        buttonBlockCenter
        breakpoints={{
          0: {
            slidesPerView: 1.5,
            spaceBetween: 16,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1200: {
            slidesPerView: slidesPerView || 4,
            spaceBetween: 16,
          },
        }}>
        {items.map(country => (
          <CountrySliderItem
            key={country.title}
            {...country}
          />
        ))}
      </Carousel>
    </>
  );
};

export default CountriesSlider;
