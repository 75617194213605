import { useEffect, useRef } from 'react';
import type { WeekDay } from '../type';
import MonthDays from '../component/month-days/month-days';
import WeekDays from '../component/week-days/week-days';
import { getDayOfMonth, isInDateRange, trimMonth, trimYear } from '../functions';
import type { ViewProps } from '../view-manager';
import { Box, Stack, Typography } from '@mui/material';

export default function ScrollView(props: ViewProps) {
  const { date, dateRange, calendar, limit, locale, onSelect, currentDate } = props;

  let scroll: number | null = null;

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const selectedMonthRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scroll && scrollContainerRef.current && selectedMonthRef.current) {
      scrollContainerRef.current.scrollTo(0, selectedMonthRef.current.offsetTop);
    }
  }, [scrollContainerRef, selectedMonthRef, scroll]);

  const view: {
    year: number;
    month: number;
    calendarMonth: { days: number; firstDayWeekDay: WeekDay };
    dayLimit: { start: number; end: number };
    day?: number;
    dayRange?: { start?: number; end?: number };
    defaultStartOfView: boolean;
  }[] = [];
  if (limit) {
    for (let year = limit.start.year; year <= limit.end.year; year++) {
      const { startMonthIndex, endMonthIndex } = trimYear(year, calendar.monthCount, limit);

      for (let month = startMonthIndex; month <= endMonthIndex; month++) {
        const calendarMonth = calendar.getMonth(year, month);

        const { startDay: startLimitDay, endDay: endLimitDay } = trimMonth(
          year,
          month,
          calendarMonth.days,
          limit
        );
        const dayLimit = { start: startLimitDay, end: endLimitDay };

        const day = getDayOfMonth(year, month, date);

        let dayRange;
        let dayRangeStart = getDayOfMonth(year, month, dateRange?.start);
        let dayRangeEnd = getDayOfMonth(year, month, dateRange?.end);

        const firstDayInRange =
          dateRange &&
          dateRange.start &&
          dateRange.end &&
          isInDateRange({ year, month, day: 1 }, { start: dateRange.start, end: dateRange.end });

        if (
          dayRangeStart === undefined &&
          dateRange?.start &&
          (dayRangeEnd !== undefined || firstDayInRange)
        )
          dayRangeStart = 0;
        if (
          dayRangeEnd === undefined &&
          dateRange?.end &&
          (dayRangeStart !== undefined || firstDayInRange)
        )
          dayRangeEnd = calendarMonth.days + 1;
        if (dayRangeStart !== undefined || dayRangeEnd !== undefined) {
          dayRange = { start: dayRangeStart, end: dayRangeEnd };
        }

        const defaultStartOfView =
          getDayOfMonth(year, month, date) !== undefined ||
          getDayOfMonth(year, month, dateRange?.start) !== undefined ||
          (day === undefined &&
            dateRange === undefined &&
            getDayOfMonth(year, month, currentDate) !== undefined);

        view.push({
          year,
          month,
          calendarMonth,
          dayLimit,
          day,
          dayRange,
          defaultStartOfView,
        });
      }
    }
  }

  return (
    <Box height='100%'>
      <Box>
        <WeekDays
          labels={locale.weekDays}
          startDayOfWeek={locale.firstDayOfTheWeek}
        />
      </Box>
      <Box
        overflow='scroll'
        height='100%'
        ref={scrollContainerRef}>
        {view.map((monthView, index) => (
          <Box
            mb={2}
            key={index}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              mb={1}
              ref={monthView.defaultStartOfView ? selectedMonthRef : undefined}>
              <Typography
                fontWeight={800}
                fontSize={16}>
                {locale.months[monthView.month - 1]}
              </Typography>
            </Stack>
            <MonthDays
              startDayOfWeek={locale.firstDayOfTheWeek}
              firstDayWeekDay={monthView.calendarMonth.firstDayWeekDay}
              dayCount={monthView.calendarMonth.days}
              limit={monthView.dayLimit}
              day={monthView.day}
              range={monthView.dayRange}
              onSelect={dayNumber =>
                onSelect &&
                onSelect({
                  year: monthView.year,
                  month: monthView.month,
                  day: dayNumber,
                })
              }
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
