import React, { type FunctionComponent } from 'react';
import type { Calendar } from '././calendar';
import { toDateObject, toLocalDateRange, toLocalPartialDateRange } from './functions';
import type Locale from './locale';
import type { DateObject, DateRangeObject, WeekDay } from './type';

export type ViewProps = {
  calendar: {
    monthCount: number;
    getMonth(
      year: number,
      month: number
    ): {
      days: number;
      firstDayWeekDay: WeekDay;
    };
  };
  locale: {
    firstDayOfTheWeek: WeekDay;
    weekDays: Record<WeekDay, string>;
    months: string[];
  };
  limit?: DateRangeObject;
  date?: DateObject;
  dateRange?: Partial<DateRangeObject>;
  onSelect?(date: DateObject): void;
  currentDate: DateObject;
};

export type ViewComponent = FunctionComponent<ViewProps>;

type ViewManagerProps = {
  view: ViewComponent;
  limit?: DateRangeObject;
  date?: DateObject;
  dateRange?: Partial<DateRangeObject>;

  locale: Locale;
  calendar: Calendar;

  onSelect?(date: DateObject): void;
};

export default function ViewManager(props: ViewManagerProps) {
  const { view, limit, calendar, locale, date, dateRange, onSelect } = props;

  let localLimit = limit && toLocalDateRange(calendar, limit);
  let localDate = date && calendar.toLocalDateObject(date);
  let localDateRange = dateRange && toLocalPartialDateRange(calendar, dateRange);
  const localeCalender = locale.calendars.find(
    localeCalender => localeCalender.calendar === calendar
  );
  if (!localeCalender) {
    throw new Error('The locale provided does not contain the calendar specified.');
  }

  let currentLocalDate = calendar.toLocalDateObject(toDateObject(new Date()));

  return React.createElement(view, {
    limit: localLimit,
    date: localDate,
    dateRange: localDateRange,
    onSelect: date => onSelect && onSelect(calendar.toGregoryDateObject(date)),
    currentDate: currentLocalDate,
    calendar: {
      monthCount: calendar.monthCount,
      getMonth: calendar.getMonth,
    },
    locale: {
      firstDayOfTheWeek: locale.firstDayOfTheWeek,
      weekDays: locale.weekDays,
      months: localeCalender.months,
    },
  });
}
