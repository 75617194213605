import { useState } from 'react';
import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  Box,
  Dialog,
  Paper,
  Popper,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import { useLanguage } from '@/components/common/language/language';
import SpinnerLoading from '@/components/common/spinner-loading/spinner-loading';
import { TextFieldModified } from '@/components/common/textfield-modified/textfield-modified';
import palette from '@/components/common/styles/color.module.scss';
import type { HotelCityOptionAutocompleteType } from '@/components/hotels/search-fields-hotels/search-fields-hotels.types';
import type { SelectHotelProps } from '@/components/hotels/search-fields-hotels/components/select-hotel/select-hotel.types';
import Option from '@/components/hotels/search-fields-hotels/components/select-hotel/components/option/option';
import { scrollBarStyle } from '@/components/common/styles/style';

const textFieldStyle = {
  '& .MuiInputBase-root': {
    paddingLeft: '0',
  },
  '& input': {
    paddingLeft: '14px',
  },
  '& .MuiInputBase-root.Mui-focused .MuiInputAdornment-root .icon-building': {
    color: palette.primary,
  },
};

export default function SelectHotel(props: SelectHotelProps) {
  const { data, searchData, setSearchHotel, setSearchDataHotelCity, loading } = props;
  const device = useDevice();
  const languageJson = useLanguage();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentDialogTextField, setCurrentDialogTextField] = useState(searchData.hotelCity.label);
  const isMobile = device === DeviceEnum.MOBILE;
  const loadingText = <SpinnerLoading size={24} />;
  const options = loading
    ? []
    : data.reduce(
        (
          sum: HotelCityOptionAutocompleteType[],
          current: {
            group: { key: string; label: string };
            items: HotelCityOptionAutocompleteType[];
          }
        ) => sum.concat(current.items),
        []
      );
  const groupLabelStyle = {
    backgroundColor: 'white',
    padding: '4px 8px !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '20px !important',
    color: `${palette.gray_darker_60} !important`,
    top: '0 !important',
    position: 'relative',
    zIndex: '0',
    '&::before': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#00968814 !important',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
  };
  const autoCompleteOption = {
    position: 'relative',
    mt: 1,
    '& > div': {
      position: 'relative',
      mt: 1,
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '-5px',
        height: '1px',
        backgroundColor: palette.gray_light_40,
      },
    },
  };
  const inputProps = {
    startAdornment: (
      <Box
        position='absolute'
        top={8}
        right={6}
        fontSize={24}
        color={palette.gray}
        component='span'
        className='icon-building'></Box>
    ),
  };
  function PaperComponent(props: any) {
    return (
      <Paper
        {...props}
        sx={{
          position: {
            xs: 'static',
            md: 'relative',
          },
          width: {
            xs: '100%',
            md: 'auto',
          },
          padding: '12px 8px',
          '& > ul': {
            padding: 0,
            overflowX: 'hidden',
            pl: '2px',
            ...scrollBarStyle,
          },
          '& .sub': {
            ml: 3,
          },
        }}
      />
    );
  }
  function groupBy(option: HotelCityOptionAutocompleteType) {
    return option.group === 'city'
      ? languageJson.common.city
      : option.group === 'hotel'
      ? languageJson.common.hotel
      : '';
  }
  function onChange(e: any, value: HotelCityOptionAutocompleteType) {
    setSearchDataHotelCity({ ...searchData, hotelCity: value });
  }
  function renderOption(props: any, option: HotelCityOptionAutocompleteType) {
    return (
      <Box
        component='li'
        {...props}
        key={option.value}
        sx={autoCompleteOption}
        className=''>
        <Option option={option} />
      </Box>
    );
  }
  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextFieldModified
        {...params}
        onChange={e => setSearchHotel(e.target.value)}
        onClick={() => {
          isMobile && setOpenDialog(true);
          setSearchHotel(searchData.hotelCity.label);
        }}
        size='small'
        label={languageJson.common.city_hotel_name}
        variant='outlined'
      />
    );
  }

  const StyledPopper = styled(Popper)(() => ({
    '& .MuiAutocomplete-groupLabel': groupLabelStyle,
  }));
  return (
    <>
      <Box position='relative'>
        <Box
          position='absolute'
          top={6}
          right={12}
          color={palette.gray}
          fontSize={28}
          component='span'
          className='icon-building'></Box>
        <Autocomplete
          PopperComponent={StyledPopper}
          popupIcon={<></>}
          disableClearable
          readOnly={isMobile}
          loading={loading}
          loadingText={loadingText}
          filterOptions={options => options}
          groupBy={groupBy}
          options={options}
          PaperComponent={PaperComponent}
          onChange={onChange}
          getOptionLabel={option => option.label}
          value={searchData.hotelCity}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={renderOption}
          renderInput={renderInput}
        />
      </Box>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={() => setOpenDialog(false)}>
        <Stack
          direction='row'
          alignItems='center'
          position='relative'
          p={2}
          pb={2.5}
          spacing={2}
          sx={{
            borderBottom: `1px solid ${palette.gray}`,
          }}>
          <Box
            sx={{
              cursor: 'pointer',
              rotate: '180deg',
            }}
            fontSize={20}
            component='span'
            className='icon-arrow-right'
            onClick={() => setOpenDialog(false)}></Box>
          <Typography variant='t3_regular'>{languageJson.common.city_hotel_name}</Typography>
        </Stack>
        <Box
          mt='10px'
          p={2}
          pb={{
            xs: 0,
            md: 2,
          }}>
          <Stack
            position='relative'
            alignItems='center'>
            <TextFieldModified
              sx={textFieldStyle}
              InputProps={inputProps}
              onChange={e => {
                setSearchHotel(e.target.value);
                setCurrentDialogTextField(e.target.value);
              }}
              onClick={e => setSearchHotel((e.target as HTMLInputElement).value)}
              value={currentDialogTextField}
              fullWidth
              size='small'
              label={languageJson.common.city_hotel_name}
              variant='outlined'
            />
          </Stack>
        </Box>
        <Box p={2}>
          <Box
            sx={{
              maxHeight: ' calc(100vh - 168px)',
              height: 'calc(100vh - 168px)',
              overflowY: 'auto',
              ...scrollBarStyle,
            }}>
            {loading ? (
              <SpinnerLoading size={24} />
            ) : (
              data.map((option, index) => (
                <Box key={index}>
                  {option.items.length > 0 && (
                    <Box sx={groupLabelStyle}>
                      <Typography variant='c1_regular'>{option.group.label}</Typography>
                    </Box>
                  )}
                  <Box>
                    {option.items.map(option => (
                      <Box
                        sx={autoCompleteOption}
                        key={option.value}
                        onClick={() => {
                          setSearchDataHotelCity({
                            ...searchData,
                            hotelCity: option,
                          });
                          setOpenDialog(false);
                          setCurrentDialogTextField(option.label);
                        }}>
                        <Option option={option} />
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
