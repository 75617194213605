import { Button } from '@/components/common/button/button';
import { useLanguage } from '@/components/common/language/language';
import type { LandingServiceModuleCardVariantProps } from '@/components/landings/landing.types';
import ServiceContinueButton from '@/components/travelers/package/components/service-continue-button';
import TravelersServicesCardVariant from '@/components/travelers/package/components/services-card-variant/services-card-variant';
import { addServiceCart, EmptyServiceCartObject } from '@/redux/cart-slice';
import { useAppSelector } from '@/redux/hooks';
import { TravelServiceInputType } from '@/services/travel-service/travel-service.types';
import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

function LandingServiceModuleCardVariant(props: LandingServiceModuleCardVariantProps) {
  // Spread Props
  const { id, name, componentOptions, travelServiceVariants, variantFields } = props.service;

  // Use Hooks
  const languageJson = useLanguage();

  // Use Global State
  const { ServiceCart } = useAppSelector(state => state.cart);
  const thisServiceCartItems = ServiceCart.filter(item => item.travelServiceId === id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!thisServiceCartItems.length) {
      addEmptyVariant();
    }
  }, [ServiceCart]);

  const addEmptyVariant = () => {
    dispatch(
      addServiceCart({
        ...EmptyServiceCartObject,
        fakeId: uuidv4(),
        travelServiceId: id,
      })
    );
  };

  // Check Special Service
  const isSpecial: boolean = componentOptions.isFeatured;

  // Constant
  const isNumberInput: boolean = variantFields.some(
    variant => variant.type === TravelServiceInputType.NUMBER_INPUT
  );

  const isVariantsCapabilityCompleted: boolean = isNumberInput
    ? !!thisServiceCartItems && thisServiceCartItems.length < 5
    : !!travelServiceVariants?.length &&
      travelServiceVariants?.length > 0 &&
      thisServiceCartItems.length !== travelServiceVariants?.length;

  const buttonText: string = React.useMemo((): string => {
    let text: string = '';

    if (!thisServiceCartItems) return languageJson.common.add_to_pack;

    switch (name) {
      case 'iran-visa':
        text = languageJson.common.add_nationality;
        break;
      default:
        text = languageJson.common.add_another;
        break;
    }
    return text;
  }, [name]);

  if (!variantFields) return;

  return (
    <Stack
      gap={3}
      p={0}>
      {thisServiceCartItems.map(variantItem => (
        <>
          <TravelersServicesCardVariant
            {...variantItem}
            key={variantItem.id}
            totalVariantsLength={
              ServiceCart?.filter(variant => variant.travelServiceId === id).length
            }
            travelServiceVariants={travelServiceVariants!}
            variantFields={variantFields}
          />
        </>
      ))}
      <Stack
        flexWrap='wrap'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        rowGap={2}>
        <Button
          disabled={!isVariantsCapabilityCompleted}
          onClick={() => {
            isVariantsCapabilityCompleted && addEmptyVariant();
          }}
          color={isSpecial ? 'secondary' : 'primary'}
          size='large'
          variant='outlined'>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            spacing={{
              xs: 0.5,
              md: '6px',
            }}>
            <Box
              component='span'
              className='icon-add'
              fontSize={16}></Box>
            <Typography variant='c1_bold'>{buttonText}</Typography>
          </Stack>
        </Button>
        <Box>
          <ServiceContinueButton size='small' />
        </Box>
      </Stack>
    </Stack>
  );
}

export default LandingServiceModuleCardVariant;
