import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ButtonModified } from '@/components/common/button-modified/button-modified';
import { useLanguage } from '@/components/common/language/language';
import ResponsiveSelect from '@/components/common/responsive-select/responsive-select';
import type { Option } from '@/components/common/responsive-select/responsive-select.types';
import type { ResponsiveRoomPassengerSelectProps } from '@/components/hotels/search-fields-hotels/components/responsive-room-passenger-select/responsive-room-passenger-select.types';
import type { RoomPassengerType } from '@/components/hotels/search-fields-hotels/search-fields-hotels.types';
import { getAdultChildCountPerRoom } from '@/components/hotels/search-fields-hotels/functions';
import SelectOption from '@/components/hotels/search-fields-hotels/components/responsive-room-passenger-select/components/select-option/select-option';
import { scrollBarStyle } from '@/components/common/styles/style';
export default function ResponsiveRoomPassengerSelect(props: ResponsiveRoomPassengerSelectProps) {
  const { onChange, searchData, label } = props;
  const [open, setOpen] = useState(false);
  const languageJson = useLanguage();
  const passengerTypes: {
    text: string;
    value: 'adult' | 'child';
  }[] = [
    {
      text:
        languageJson.state.passengers_list.adult.title +
        '-' +
        languageJson.state.passengers_list.adult.desc,
      value: 'adult',
    },
    {
      text: languageJson.state.passengers_list.child.title + '-' + languageJson.common.under_twelve,
      value: 'child',
    },
  ];
  const isDisabledIncreasePassengerCounter = (indexRoom: number, index: number): boolean => {
    const copySearchDataRoom = [...searchData];
    switch (index) {
      case 0:
        return !(copySearchDataRoom[indexRoom].passengers.adult.value < 9);
      case 1:
        return !(copySearchDataRoom[indexRoom].passengers.child.value < 4);
      default:
        return false;
    }
  };
  const isDisabledDecreasePassengerCounter = (indexRoom: number, index: number): boolean => {
    const copySearchDataRoom = [...searchData];
    switch (index) {
      case 0:
        return !(copySearchDataRoom[indexRoom].passengers.adult.value > 1);
      case 1:
        return !(copySearchDataRoom[indexRoom].passengers.child.value > 0);
      default:
        return false;
    }
  };
  const addRoom = () => {
    const newRoom = {
      roomPassenger: [
        ...searchData,
        {
          passengers: {
            adult: {
              value: 1,
              askAges: false,
              ages: [],
            },
            child: {
              value: 0,
              askAges: true,
              ages: [],
            },
          },
        },
      ],
    };
    onChange(newRoom);
  };
  const removeRoom = (index: number) => {
    const copySearchDataRoom = [...searchData];
    copySearchDataRoom.splice(index, 1);
    onChange({
      roomPassenger: copySearchDataRoom,
    });
  };
  const updateAges = (
    indexRoom: number,
    keyPassenger: 'adult' | 'child',
    value: number,
    indexPerson: number
  ) => {
    const copySearchDataRoom = [...searchData];
    const desiredPerson = copySearchDataRoom[indexRoom].passengers[keyPassenger];
    if (desiredPerson) desiredPerson.ages[indexPerson] = value;
    onChange({
      roomPassenger: copySearchDataRoom,
    });
  };
  const updatePassengerCountOfRoom = (
    keyPassenger: 'child' | 'adult',
    indexRoom: number,
    mode: 'increase' | 'decrease'
  ) => {
    let copySearchDataRoom: RoomPassengerType[] = [...searchData];
    if (copySearchDataRoom[indexRoom].passengers[keyPassenger]) {
      copySearchDataRoom = copySearchDataRoom.map((room, index) => {
        if (index === indexRoom)
          return {
            ...room,
            passengers: {
              ...room.passengers,
              [keyPassenger]: {
                ...room.passengers[keyPassenger],
                value:
                  mode === 'increase'
                    ? room.passengers[keyPassenger].value + 1
                    : room.passengers[keyPassenger].value - 1,
              },
            },
          };
        else return room;
      });
    }
    if (keyPassenger === 'child' && mode === 'decrease') {
      copySearchDataRoom = copySearchDataRoom.map((room, index) => {
        if (index === indexRoom)
          return {
            ...room,
            passengers: {
              ...room.passengers,
              [keyPassenger]: {
                ...room.passengers[keyPassenger],
                ages: [...room.passengers[keyPassenger].ages].splice(
                  room.passengers[keyPassenger].value - 1,
                  1
                ),
              },
            },
          };
        else return room;
      });
    }
    onChange({
      roomPassenger: copySearchDataRoom,
    });
  };
  const renderValue = () => {
    const countAdultChild: { adult: number; child: number } = getAdultChildCountPerRoom(searchData);
    return (
      <>{`${countAdultChild.adult} ${languageJson.state.passengers_list.adult.title}, ${countAdultChild.child} ${languageJson.state.passengers_list.child.title}, ${searchData.length} ${languageJson.common.room}`}</>
    );
  };
  function renderDrawerOptions(options: Option[], setShowDrawer: (value: boolean) => void) {
    return (
      <Box py={32}>
        {searchData.map((room, indexRoom) => (
          <SelectOption
            options={passengerTypes}
            isDisabledIncreasePassengerCounter={isDisabledIncreasePassengerCounter}
            isDisabledDecreasePassengerCounter={isDisabledDecreasePassengerCounter}
            updatePassengerCountOfRoom={updatePassengerCountOfRoom}
            updateAges={updateAges}
            removeRoom={removeRoom}
            roomPassenger={searchData}
            room={room}
            key={indexRoom}
            indexRoom={indexRoom}
          />
        ))}
        {searchData.length < 4 && (
          <Box my={2}>
            <ButtonModified
              onClick={addRoom}
              size='large'
              variant='outlined'
              startIcon={
                <Box
                  component='span'
                  className='icon-add'></Box>
              }>
              {languageJson.common.add_room}
            </ButtonModified>
          </Box>
        )}
        <Box
          position='fixed'
          left={0}
          right={0}
          bottom={0}
          py={1}
          px={2}
          zIndex={3}
          sx={{
            boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.04)',
            backgroundColor: 'white',
          }}>
          <ButtonModified
            variant='contained'
            onClick={() => setShowDrawer(false)}
            fullWidth>
            {languageJson.common.confirm}
          </ButtonModified>
        </Box>
      </Box>
    );
  }
  const customSelectOption = (
    <Stack
      position='relative'
      width={582}
      p={4}
      pb={0}
      spacing={3}>
      <Typography
        variant='t3_bold'
        textAlign='center'>
        {languageJson.common.passengers_rooms}
      </Typography>
      <Box
        maxHeight='50vh'
        overflow='auto'
        sx={{
          mr: 4,
          overflowX: 'hidden',
          ...scrollBarStyle,
        }}>
        {searchData.map((room, indexRoom) => (
          <SelectOption
            options={passengerTypes}
            isDisabledIncreasePassengerCounter={isDisabledIncreasePassengerCounter}
            isDisabledDecreasePassengerCounter={isDisabledDecreasePassengerCounter}
            updatePassengerCountOfRoom={updatePassengerCountOfRoom}
            updateAges={updateAges}
            removeRoom={removeRoom}
            roomPassenger={searchData}
            room={room}
            key={indexRoom}
            indexRoom={indexRoom}
          />
        ))}
      </Box>
      {searchData.length < 4 && (
        <Box>
          <ButtonModified
            onClick={addRoom}
            size='large'
            variant='outlined'
            startIcon={
              <Box
                component='span'
                className='icon-add'></Box>
            }>
            {languageJson.common.add_room}
          </ButtonModified>
        </Box>
      )}
      <Box
        mx='-32px'
        py={1}
        sx={{
          boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.04)',
        }}>
        <ButtonModified
          onClick={() => setOpen(false)}
          variant='contained'
          size='large'
          fullWidth>
          {`${languageJson.common.confirm} - ${getAdultChildCountPerRoom(searchData).adult} ${
            languageJson.state.passengers_list.adult.title
          }، ${searchData.length} ${languageJson.common.room}`}
        </ButtonModified>
      </Box>
    </Stack>
  );
  return (
    <ResponsiveSelect
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      value={[JSON.stringify(searchData)]}
      label={label}
      options={[]}
      multiple
      customSelectOption={customSelectOption}
      selectCustomWidth={300}
      renderValue={renderValue}
      renderDrawerOptions={renderDrawerOptions}
      sx={{
        '& fieldset': {
          borderRadius: '8px',
        },
      }}
    />
  );
}
