import { DateObject } from 'react-multi-date-picker';
import gregorian from 'react-date-object/calendars/gregorian';
import persian from 'react-date-object/calendars/persian';
import jalali from 'react-date-object/calendars/jalali';
import arabic from 'react-date-object/calendars/arabic';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import gregorian_ar from 'react-date-object/locales/gregorian_ar';

import type { Calendar, Locale } from 'react-date-object';
export const getLocale = (locale: string): Locale => {
  let defaultLocale = gregorian_en;

  if (locale === 'ar') {
    defaultLocale = gregorian_ar;
  } else if (locale === 'en') {
    defaultLocale = gregorian_en;
  }

  return defaultLocale;
};
export const getCalenders = (
  calenders: 'gregorian' | 'arabic' | 'persian' | 'jalali'
): Calendar => {
  let defaultLocale = gregorian;

  if (calenders === 'gregorian') {
    defaultLocale = gregorian;
  } else if (calenders === 'persian') {
    defaultLocale = persian;
  } else if (calenders === 'jalali') {
    defaultLocale = jalali;
  } else if (calenders === 'arabic') {
    defaultLocale = arabic;
  }

  return defaultLocale;
};
export const convertDate = (
  date: any,
  locale: string,
  mode: 'display' | 'save',
  timePicker: boolean = false
): string => {
  let generateDate = '2021/03/02';

  if (locale === 'ar' && mode === 'display') {
    generateDate = new DateObject({
      date,
      format: `YYYY/MM/DD${timePicker ? ' HH:mm:00' : ' '}`,
    })
      .convert(gregorian, gregorian_en)
      .format();
  } else if (locale === 'en' && mode === 'display') {
    generateDate = new DateObject({
      date,
      format: `YYYY/MM/DD${timePicker ? ' HH:mm:00' : ''}`,
    })
      .convert(gregorian, gregorian_en)
      .format();
  } else if (mode === 'save') {
    generateDate = new DateObject({
      date,
      format: `YYYY-MM-DDT${timePicker ? 'HH:mm:00' : '00:00:00'}`,
    })
      .convert(gregorian, gregorian_en)
      .format();
  }

  return generateDate;
};
