import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from '@/components/common/image/image';
import { A11y, Autoplay, Pagination, Scrollbar } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/a11y';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import type {
  SliderFullPaginationProps,
  SliderFullPaginationPropsImage,
} from '@/components/tours/page/index.types';

export const SliderFullPagination: React.FC<SliderFullPaginationProps> = props => {
  // Destructure Props
  const { size = 'lg', speed, loop, autoplay, images, borderRadius } = props;

  return (
    <Swiper
      modules={[Autoplay, Pagination, Scrollbar, A11y]}
      slidesPerView={1}
      spaceBetween={8}
      pagination={{ clickable: true }}
      speed={speed || 1000}
      loop={loop || false}
      className={`slider-full-pagination slider-full-pagination-${size}`}
      autoplay={
        autoplay || {
          delay: 3000,
        }
      }
      style={{
        width: '100%',
        height: '100%',
      }}>
      {images?.map((item, index) => (
        <SwiperSlide
          key={index}
          style={{
            height: '100%',
          }}>
          <SliderFullPaginationSlide
            borderRadius={borderRadius}
            {...item}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export const SliderFullPaginationSlide: React.FC<SliderFullPaginationPropsImage> = props => {
  // Destructure Props
  const { src, alt, title, borderRadius } = props;

  // Use Hooks
  const isMobile = useDevice() === DeviceEnum.MOBILE;

  return (
    <Image
      className='slider-image'
      width={800}
      height={450}
      src={src || null}
      alt={alt}
      title={title}
      style={{
        borderRadius: borderRadius || isMobile ? '8px' : '20px',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    />
  );
};
