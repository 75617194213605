/**
  This enum values are compatible with return value of Date.prototype.getDay.
  It used and can be used as a fact in writing calendars logic.
*/
export enum WeekDay {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export type DateObject = {
  year: number;
  month: number;
  day: number;
};

export type DateRangeObject = {
  start: DateObject;
  end: DateObject;
};

export type DateInterval = {
  years: number;
  months: number;
  days: number;
};
export type DateYearMonth = {
  year: number;
  month: number;
};
