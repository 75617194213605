import React from 'react';
import { useRouter } from 'next/router';
import { Box, Stack } from '@mui/material';
import palette from '@/components/common/styles/color.module.scss';
import { TextFieldModified } from '@/components/common/textfield-modified/textfield-modified';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import weekends from 'react-multi-date-picker/plugins/highlight_weekends';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import CustomDesktopDatePicker from '@/components/common/date-picker/multi-date-picker/desktop-date-picker';
import CustomMobileDatePicker from '@/components/common/date-picker/multi-date-picker/mobile-date-picker';
import type { FormikDateProps } from '@/components/common/formik';
import type { Plugin } from 'react-multi-date-picker';
import {
  convertDate,
  getCalenders,
  getLocale,
} from '@/components/common/date-picker/multi-date-picker/function';

export const FormikDate: React.FC<FormikDateProps> = props => {
  // Destructure Props
  const {
    name,
    sx,
    label,
    error,
    value,
    onChange,
    helperText,
    onBlur,
    timePicker,
  }: FormikDateProps = props;

  // Use Hooks
  const route = useRouter();
  const isMobile = useDevice() === DeviceEnum.MOBILE;

  // Date Picker Config
  const CustomDatePicker = isMobile ? CustomMobileDatePicker : CustomDesktopDatePicker;
  const plugins: Plugin | Plugin[] = [weekends()];
  const dateNow = value
    ? convertDate(Date.parse(value), route.locale || 'en', 'display', timePicker)
    : '';
  if (timePicker) {
    plugins.push(
      <TimePicker
        className='rmdp-border-top'
        key={1}
        position='bottom'
        hideSeconds
      />
    );
  }

  return (
    <CustomDatePicker
      format={timePicker ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD'}
      plugins={plugins}
      value={dateNow}
      placeholder={dateNow}
      calendar={getCalenders('gregorian')}
      locale={getLocale(route.locale || 'en')}
      onChange={date => {
        if (!date) return;
        onChange(name, convertDate(date, route.locale || 'en', 'save', timePicker));
      }}
      name={name}
      render={(value, openCalendar) => {
        return (
          <TextFieldModified
            fullWidth
            id={name}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            helperText={helperText}
            sx={{
              ...sx,
              label: {
                pr: 3.5,
              },
            }}
            size='small'
            variant='outlined'
            onClick={openCalendar}
            InputProps={{
              endAdornment: (
                <Stack
                  direction='row'
                  spacing={1}>
                  {value && (
                    <Box
                      fontSize={{
                        xs: 20,
                        md: 24,
                      }}
                      onClick={() => {
                        onChange(name, '');
                      }}
                      color={error ? palette.error : palette.gray}
                      component='span'
                      className='icon-close'
                      sx={{
                        cursor: 'pointer',
                      }}></Box>
                  )}

                  <Box
                    fontSize={{
                      xs: 20,
                      md: 24,
                    }}
                    color={error ? palette.error : palette.gray}
                    component='span'
                    className='icon-calendar'></Box>
                </Stack>
              ),
            }}
          />
        );
      }}
    />
  );
};
