import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Button } from '@/components/common/button/button';
import { useLanguage } from '@/components/common/language/language';
import SelectTravelStyle from '@/components/tours/search-fields-tours/components/select-travel-style/select-travel-style';
import { Size } from '@/components/common/responsive-select/responsive-select.types';
import { citiesOptions } from '@/components/common/constant/constant';
import { FormikDate } from '@/components/common/formik/date';
import { useRouter } from 'next/router';
import SpinnerLoading from '@/components/common/spinner-loading/spinner-loading';

export const LandingServiceModuleTour: React.FC = () => {
  // Use States
  const [filter, setFilter] = React.useState<{
    cities: string[];
    date: string;
  }>({
    cities: [],
    date: '',
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Use Hooks
  const languageJson = useLanguage();
  const router = useRouter();
  const { query, push } = router;

  // Functions
  const searchHandler = () => {
    setIsLoading(true);
    if (!!filter.cities) {
      query.cities = filter.cities;
    } else {
      delete query.cities;
    }
    if (!!filter.date) {
      query.date = filter.date;
    } else {
      delete query.date;
    }
    push(router).then(() => {
      setIsLoading(false);
    });
  };

  // Effects
  React.useEffect(() => {
    setFilter({
      cities: (router.query.cities as string[]) || [],
      date: (router.query.date as string) || '',
    });
  }, [router]);

  return (
    <Stack
      direction={{
        xs: 'column',
        md: 'row',
      }}
      gap={3}
      alignItems={{
        xs: 'flex-end',
        md: 'center',
      }}
      justifyContent='space-between'
      p={0}>
      <Box
        flex={1}
        width={1}>
        <SelectTravelStyle
          size={Size.small}
          label={languageJson.common.destination}
          value={filter.cities}
          onChange={value => setFilter({ ...filter, cities: value as string[] })}
          options={citiesOptions}
          sx={{
            width: 1,
          }}
        />
      </Box>
      <Box
        flex={1}
        width={1}>
        <FormikDate
          name='Date'
          label={languageJson.common.date}
          value={filter.date}
          onChange={(_name: string, _date: string) => {
            setFilter({ ...filter, date: _date?.replace('T00:00:00', '') || '' });
          }}
        />
      </Box>
      <Stack
        direction='row'
        justifyContent='flex-end'>
        <Box>
          <Button
            fullWidth
            disabled={isLoading || !(filter.cities.length > 0 || !!filter.date)}
            color='primary'
            size='large'
            variant={isLoading ? 'outlined' : 'contained'}
            onClick={searchHandler}
            sx={{
              px: {
                xs: isLoading ? '53px' : '42px',
                md: isLoading ? '73px' : '55px',
              },
            }}>
            <Typography
              sx={{
                typography: {
                  xs: 'c1_bold',
                  md: 'b1_bold',
                },
              }}>
              {isLoading ? (
                <Box
                  py={{
                    xs: 0,
                    md: 0.5,
                  }}>
                  <SpinnerLoading size={20} />
                </Box>
              ) : (
                languageJson.common.search
              )}
            </Typography>
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
