import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import palette from '@/components/common/styles/color.module.scss';
import type { LandingPageHeroSectionProps } from '@/components/landings/landing.types';
import Image from '@/components/common/image/image';
import LandingPageServicesModule from './service-component/service-container';

// import LandingPageServicesModule from '@/components/landings/hero-section/service-component/service-container';

const LandingPageHeroSection: React.FC<LandingPageHeroSectionProps> = props => {
  // Destructure Props
  const { title, description, thumbnail, active, slug, services } = props;

  // Find Service
  const serviceSelected = services?.find(({ name }) => name === slug) || services?.[0];

  return (
    <React.Fragment>
      <Box
        sx={{
          height: {
            xs: '509px',
            md: '487px',
          },
          maxWidth: '1920px',
          width: '100%',
          img: {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          },
          margin: 'auto',
          position: 'absolute',
          right: '50%',
          transform: 'translateX(50%)',
          zIndex: -10,
        }}>
        <Image
          priority
          width={thumbnail.width}
          height={thumbnail.height}
          src={thumbnail.src}
          alt={thumbnail.alt}
        />
      </Box>

      <Stack spacing={{ md: 2 }}>
        <Stack alignItems={{ xs: 'center', md: 'unset' }}>
          <Stack
            mt={{
              xs: '84px',
              md: '100px',
            }}
            maxWidth={{ xs: 278, md: 'unset' }}
            width={1}
            mb={!active ? { xs: '150px', sm: '200px', md: '250px' } : '8px'}
            // spacing={{
            //   xs: '-18px',
            //   md: '-38px',
            // }}
          >
            <Typography
              color={palette.white}
              component='h1'
              sx={{
                typography: {
                  xs: 't1_bold',
                  md: 'h1_bold',
                },
              }}>
              {title}
            </Typography>
            {!!description && (
              <Typography
                component={'div'}
                color={palette.white}
                sx={{
                  typography: {
                    xs: 'b1_regular',
                    md: 't2_regular',
                  },
                  '& span *': {
                    m: 'unset',
                  },
                }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </Typography>
            )}
          </Stack>
        </Stack>
        {!!services && serviceSelected && active && (
          <Box>
            <LandingPageServicesModule
              services={services}
              service={serviceSelected}
              slug={slug}
            />
          </Box>
        )}
      </Stack>
    </React.Fragment>
  );
};

export default LandingPageHeroSection;
