import type { WeekDay } from '@/components/common/date-picker/type';
import { Box, Stack, Typography } from '@mui/material';
import palette from '@/components/common/styles/color.module.scss';
type MonthDaysProps = {
  firstDayWeekDay: WeekDay;
  startDayOfWeek: WeekDay;
  dayCount: number;
  onSelect?: (dayIndex: number) => void;
  range?: { start?: number; end?: number };
  day?: number;
  limit?: {
    start?: number;
    end?: number;
  };
};

export default function MonthDays(props: MonthDaysProps) {
  const { startDayOfWeek, firstDayWeekDay, dayCount, onSelect, day, range, limit } = props;
  const daysInWeek = 7;

  const dayPadding = (daysInWeek - (startDayOfWeek - firstDayWeekDay)) % daysInWeek;

  return (
    <Stack
      direction='row'
      boxSizing='border-box'
      flexWrap='wrap'
      width='100%'
      sx={{
        userSelect: 'none',
      }}>
      {new Array(dayPadding).fill(1).map((item, index) => (
        <Box
          component='span'
          sx={{
            flex: '0 1 calc(100% / 7)',
          }}
          key={index}></Box>
      ))}
      {new Array(dayCount).fill(1).map((value, dayIndex) => {
        const dayNumber = dayIndex + 1;
        const disabled =
          limit &&
          ((limit.start && limit.start > dayNumber) || (limit.end && limit.end < dayNumber));

        let classes = {
          flex: '0 1 calc(100% / 7)',
          cursor: 'pointer',
          height: '32px',
          fontSize: '14px',
          padding: '5px',
          margin: '1px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            borderRadius: ' 6px',
            backgroundColor: palette.gray_light_40,
          },
        };

        const conditionalClasses = [
          {
            condition: dayNumber === day,
            classes: {
              backgroundColor: palette.primary,
              color: palette.white,
              borderRadius: '6px',
            },
          },
          {
            condition: dayNumber === range?.start,
            classes: {
              backgroundColor: `${palette.primary} !important`,
              color: palette.white,
              borderRadius: '6px 0 0 6px',
            },
          },
          {
            condition: dayNumber === range?.end,
            classes: {
              backgroundColor: palette.primary,
              color: palette.white,
              borderRadius: '0 6px 6px 0',
            },
          },
          {
            condition:
              range &&
              range.start !== undefined &&
              dayNumber > range.start &&
              range.end !== undefined &&
              dayNumber < range.end,
            classes: {
              borderRadius: '0px !important',
              backgroundColor: palette.gray_light_40,
            },
          },
          {
            condition: disabled,
            classes: {
              cursor: 'default',
              opacity: '0.2',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        ];

        const reduceClasses = conditionalClasses.reduce(
          (list, condition) => list.concat(condition.condition ? condition.classes : []),
          new Array<object>()
        );

        return (
          <Typography
            sx={{
              ...classes,
              ...(reduceClasses[0] && reduceClasses[0]),
            }}
            onClick={!disabled ? onSelect && (() => onSelect(dayNumber)) : undefined}
            key={dayNumber}>
            {dayNumber}
          </Typography>
        );
      })}
    </Stack>
  );
}
