import { useLanguage } from '@/components/common/language/language';
import Price from '@/components/common/price';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Size } from '@travel-platform/commons/src';
import TextFieldForm from '@travel-platform/commons/src/components/common/react-hook-form/components/text-field-form/text-field-form';
import { useState } from 'react';
import { Controller, type FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import palette from '@/components/common/styles/color.module.scss';
import { apiDebitCardTopUp } from '@/services/debit-card';
import { setDebitCardTopupData, snackbarAlertSave, useAppSelector } from '@/redux';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import SpinnerLoading from '@/components/common/spinner-loading/spinner-loading';

const DebitCardTopUpForm = () => {
  const languageJson = useLanguage();
  const { auth } = useAppSelector(state => state.auth);
  const dispatch = useDispatch();
  const router = useRouter();
  const isMobile = useDevice() === DeviceEnum.MOBILE;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // Form Hook
  const formSchema = yup.object().shape({
    cardNumber: yup
      .string()
      .required(languageJson.common.required_field)
      .matches(/^5022294400?\d{6}$/, languageJson.common.not_valid),
    amount: yup
      .string()
      .required(languageJson.common.required_field)
      .matches(/^\d{0,4}$/, languageJson.common.not_valid),
    tandc: yup.boolean().test('tandc', 'You must accept the terms and conditions', val => {
      return val;
    }),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<{ cardNumber: string; amount: string; tandc: boolean | undefined }>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const totalPrice = watch('amount');

  const submit = async (data: FieldValues) => {
    setLoading(true);
    try {
      const res = (
        await apiDebitCardTopUp(
          {
            amount: Number(data.amount),
            cardNumber: data.cardNumber,
          },
          auth?.token
        )
      ).data;
      dispatch(setDebitCardTopupData(res));
      router.push('/order/debit-card-topup-confirm');
    } catch (err: any) {
      console.error(err);
      setLoading(false);
      dispatch(
        snackbarAlertSave({
          value:
            err.response?.data?.message?.toString() ||
            err.response?.data?.messages?.toString() ||
            languageJson.common.problem_occurred,
          show: true,
          severity: 'error',
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box
        display='flex'
        flexDirection='column'
        gap='16px'>
        <TextFieldForm
          name='cardNumber'
          control={control}
          register={register}
          helperText={errors['cardNumber']?.message}
          error={!!errors['cardNumber']?.message}
          size={Size.small}
          placeholder='5022294400******'
          label={languageJson.common.debit_card_number}
          inputProps={{ maxLength: 16 }}
        />
        <TextFieldForm
          name='amount'
          control={control}
          register={register}
          helperText={errors['amount']?.message}
          error={!!errors['amount']?.message}
          size={Size.small}
          label={languageJson.common.top_up_amount}
          inputProps={{ maxLength: 4 }}
        />
        <Divider />
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'>
          <Typography
            fontWeight='bold'
            fontSize={{
              xs: '16px',
              md: '24px',
            }}
            lineHeight={{
              xs: '',
              md: '40px',
            }}>
            {languageJson.common.total_price}
          </Typography>
          <Stack>
            <Price
              boxStyle={{ justifyContent: 'end' }}
              amountStyle={{
                typography: {
                  xs: 't3_bold',
                  md: 't2_bold',
                },
                textAlign: 'end',
                color: palette.primary,
              }}
              amount={totalPrice || 0}
              discountType={'percentage'}
            />
          </Stack>
        </Box>
        <ListItemButton
          sx={{ padding: 0 }}
          onClick={() => setOpen(!open)}>
          <ListItemText primary={languageJson.common.terms_conditions} />
          {open ? (
            <span className='icon-chevron-light-up'></span>
          ) : (
            <span className='icon-chevron-light-down'></span>
          )}
        </ListItemButton>
        <Collapse
          in={open}
          timeout='auto'
          unmountOnExit>
          {languageJson.common.top_up_terms_and_conditions.map((item, i) => (
            <Box
              key={i}
              position='relative'
              paddingLeft={languageJson.direction === 'ltr' ? '16px' : '16px'}>
              <Box
                width='4px'
                height='4px'
                position='absolute'
                left='0px'
                top='10px'
                bgcolor='black'
                borderRadius='50%'></Box>
              <Typography>{item}</Typography>
            </Box>
          ))}
        </Collapse>
        <Box>
          <label
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              userSelect: 'none',
            }}>
            <Controller
              name='tandc'
              control={control}
              render={({ field }) => <Checkbox {...field} />}
            />
            <Typography>{languageJson.common.agree_terms_and_conditions}</Typography>
          </label>
          {errors?.tandc && (
            <Typography
              color={palette.error}
              fontSize='12px'>
              {errors?.tandc?.message}
            </Typography>
          )}
        </Box>
        {!isMobile ? (
          <Button
            disabled={loading}
            type='submit'
            variant='contained'>
            {loading ? (
              <SpinnerLoading
                size={24}
                color='white'
              />
            ) : (
              languageJson.common.pay
            )}
          </Button>
        ) : (
          <Box
            width='100%'
            position='sticky'
            bottom='0px'
            bgcolor='white'
            padding='8px 0px 16px'>
            <Button
              disabled={loading}
              type='submit'
              fullWidth
              variant='contained'>
              {loading ? (
                <SpinnerLoading
                  size={24}
                  color='white'
                />
              ) : (
                languageJson.common.pay
              )}
            </Button>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default DebitCardTopUpForm;
