import React from 'react';
import type { LandingServiceModuleCardHeaderProps } from '@/components/landings/landing.types';
import { Box, Stack } from '@mui/material';
import useDevice, { DeviceEnum } from '@/components/common/hooks/use-device';
import LandingServiceModuleCardHeaderMobileItem from '@/components/landings/hero-section/service-component/views/landing-service-module-card-header-mobile-item';
import LandingServiceModuleCardHeaderDesktopItem from '@/components/landings/hero-section/service-component/views/landing-service-module-card-header-desktop-item';
import palette from '@/components/common/styles/color.module.scss';
import { useRouter } from 'next/router';

const LandingServiceModuleCardHeader: React.FC<LandingServiceModuleCardHeaderProps> = props => {
  // Destructure Props
  const { services, service } = props;
  const router = useRouter();
  // Use Hooks
  const isMobile = useDevice() === DeviceEnum.MOBILE;

  return !isMobile ? (
    <Stack
      direction='row'
      border={`1px solid ${palette.primary}`}
      borderBottom={{
        md: 'none',
      }}
      pt={2}
      pb={0}
      px={4}
      borderRadius={{
        xs: 4,
        md: '16px 16px 0  0 ',
      }}
      sx={{
        backgroundColor: palette.white,
      }}
      spacing={router.locale === 'ar' ? 2.2 : 1.3}>
      {services.map(({ id, title, componentOptions: { icon, isNew }, name }) => (
        <LandingServiceModuleCardHeaderDesktopItem
          key={id}
          id={id}
          title={title}
          icon={icon}
          name={name}
          isNew={isNew}
          isActive={service.name === name}
        />
      ))}
    </Stack>
  ) : (
    <Box>
      <Stack
        flexWrap={'wrap'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        columnGap={2}
        rowGap={2}
        mb={1}>
        {services.map(({ id, title, componentOptions: { icon, isNew }, name }) => (
          <Stack
            alignItems='center'
            width={'auto'}
            key={id}>
            <LandingServiceModuleCardHeaderMobileItem
              id={id}
              title={title}
              icon={icon}
              name={name}
              isNew={isNew}
              isActive={service.name === name}
            />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default LandingServiceModuleCardHeader;
