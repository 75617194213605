import { WeekDay } from '@/components/common/date-picker/type';
import { Stack, Typography } from '@mui/material';
import palette from '@/components/common/styles/color.module.scss';
type WeekDaysProps = {
  startDayOfWeek: WeekDay;
  labels: Record<WeekDay, string>;
};

const indexedWeekDay = {
  0: WeekDay.SUNDAY,
  1: WeekDay.MONDAY,
  2: WeekDay.TUESDAY,
  3: WeekDay.WEDNESDAY,
  4: WeekDay.THURSDAY,
  5: WeekDay.FRIDAY,
  6: WeekDay.SATURDAY,
};

export default function WeekDays(props: WeekDaysProps) {
  const daysInWeek = 7;

  const { startDayOfWeek, labels } = props;
  const weekDayIndexes = new Array(daysInWeek)
    .fill(1)
    .map((value, index) => ((index + startDayOfWeek) % daysInWeek) as 0 | 1 | 2 | 3 | 4 | 5 | 6);

  return (
    <Stack
      direction='row'
      flexWrap='wrap'
      width='100%'
      py={0.5}
      px={1}
      sx={{
        backgroundColor: palette.gray_light_40,
        userSelect: 'none',
      }}>
      {weekDayIndexes.map(weekDayIndex => (
        <Typography
          sx={{
            flex: '0 1 calc(100% / 7)',
            color: '#555555',
            textOverflow: 'ellipsis',
          }}
          overflow='hidden'
          textAlign='center'
          fontSize='14px'
          key={weekDayIndex}>
          {labels[indexedWeekDay[weekDayIndex]]}
        </Typography>
      ))}
    </Stack>
  );
}
