import Image from '@/components/common/image/image';
import { Stack, Typography } from '@mui/material';
import type { CmsLandingComponentsSliderItem } from '@travel-platform/commons/src/types';
import { useRouter } from 'next/router';

const CountrySliderItem = (props: CmsLandingComponentsSliderItem) => {
  const { imageUrl, title, description, url } = props;
  const router = useRouter();
  return (
    <Stack
      sx={{ position: 'relative', cursor: 'pointer' }}
      onClick={() => router.push(url)}>
      <Image
        src={imageUrl}
        style={{ objectFit: 'cover' }}
        width={{
          xs: 224,
          md: 264,
        }}
        height={{
          xs: 451,
          md: 480,
        }}
        alt={title}></Image>
      <Stack
        position={'absolute'}
        color={'white'}
        bottom={32}
        left={16}
        right={16}>
        <Typography
          sx={{ typography: { xs: 't3_bold', md: 't2_bold' } }}
          mb={1}>
          {title}
        </Typography>
        <Typography
          sx={{ typography: { xs: 'c1_regular', md: 'b2_regular' } }}
          mb={1}>
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CountrySliderItem;
