import React from 'react';
import { type Departure } from '@/services';
import { TourCard } from '@/components/common/tour-card';
import { Carousel } from '@/components/common/carousel';

export const JoinOurGroup: React.FC<{
  departure: Departure[];
}> = ({ departure }) => {
  return (
    <Carousel
      loop
      sliderStyle={{
        maxWidth: '245px',
        marginRight: '16px',
      }}
      breakpoints={{
        360: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },
        600: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
      }}>
      {departure.map((item: Departure, index) => (
        <TourCard
          key={index}
          id={item?.tour?.id}
          slug={item?.tour?.slug || null}
          title={item.title}
          description={item.description}
          from_price={item.from_price}
          date={item.start_date}
          tour_leader={item.tour_leader}
          media={!!item.tour.thumbnail ? [{ ...item.tour.thumbnail }] : undefined}
        />
      ))}
    </Carousel>
  );
};
