import { Box, Button, Stack, Typography } from '@mui/material';
import type { SelectOptionProps } from './select-option.types';
import palette from '@/components/common/styles/color.module.scss';

export default function SelectOption(props: SelectOptionProps) {
  const { label, subLabel, count, decreasable, increase, decrease, increasable } = props;

  const iconButton = {
    backgroundColor: palette.primary + ' !important',
    borderRadius: '6px',
    minWidth: '32px',
    height: '32px',
    padding: '0',
    boxShadow: '0 0 0 0',
    color: palette.white + ' !important',
  };
  return (
    <Box
      width='100%'
      px={{
        xs: 0,
        md: 2,
      }}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mt={2.5}
        my={1.5}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}>
          <Typography
            fontWeight={400}
            fontSize={16}
            lineHeight='28px'
            color='black'>
            {label}
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={12}
            lineHeight='20px'
            color='gray'>
            {subLabel}
          </Typography>
        </Stack>
        <Stack
          direction='row-reverse'
          alignItems='center'>
          <Box>
            <Button
              sx={{
                ...iconButton,
                ...(increasable && { opacity: '0.5' }),
              }}
              disabled={increasable}
              variant='contained'
              size='medium'
              onClick={increase}>
              <Box
                component='span'
                className='icon-add'></Box>
            </Button>
          </Box>
          <Box
            width='20px'
            mx={2}
            textAlign='center'>
            {count}
          </Box>
          <Box>
            <Button
              sx={{
                ...iconButton,
                ...(decreasable && { opacity: '0.5' }),
              }}
              disabled={decreasable}
              variant='contained'
              size='medium'
              onClick={decrease}>
              <Box
                component='span'
                className='icon-minus'></Box>
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
