import { useRef } from 'react';

export default function useTimeout() {
  let timeout = useRef<any>(null);

  const handleSetTimeout = (duration: number, callback: () => void) => {
    timeout.current = setTimeout(callback, duration);
  };
  const handleClearTimeout = () => {
    clearTimeout(timeout.current);
  };
  return [handleSetTimeout, handleClearTimeout];
}
