import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import { apiSearchCityNames, apiSearchHotelNames } from '@/services/hotels/hotels';
import type {
  SearchCityNamesRequest,
  SearchCityNamesResponse,
  SearchHotelNamesRequest,
} from '@/services/hotels/hotels.types';
import { toDateObject } from '@/components/common/date-picker/functions';
import type { DateObject } from '@/components/common/date-picker/type';
import { useLanguage } from '@/components/common/language/language';
import staticData from './components/select-hotel/static-data.json';
import { useDispatch } from 'react-redux';
import useTimeout from '@/components/common/hooks/use-timeout';
import { Box, Stack } from '@mui/material';
import {
  hotelSearchClearExpirationDate,
  hotelSearchClearSearchToken,
  hotelSearchSaveSearchData,
} from '@/redux/hotel-search-slice';
import type {
  HotelCityOptionAutocompleteType,
  HotelSearchDataType,
  SearchFieldsHotelsProps,
} from '@/components/hotels/search-fields-hotels/search-fields-hotels.types';
import {
  generateSearchParamsHotel,
  isChildWithoutAge,
} from '@/components/hotels/search-fields-hotels/functions';
import SelectHotel from '@/components/hotels/search-fields-hotels/components/select-hotel/select-hotel';
import SelectDate from '@/components/hotels/search-fields-hotels/components/select-date/select-date';
import ResponsiveRoomPassengerSelect from '@/components/hotels/search-fields-hotels/components/responsive-room-passenger-select/responsive-room-passenger-select';
import { name_to_url } from '@/components/common/functions/name-to-url';
import { BOOKING_URL } from '@/components/common/constant/constant';
import { Button } from '@/components/common/button/button';
import { addHotelSearchHistory } from '@travel-platform/commons/src/redux/hotel-search-history-slice';
import { v4 as uuidv4 } from 'uuid';

export default function SearchFieldsHotels(props: SearchFieldsHotelsProps) {
  const { hiddenSearchBtn, setShowDialogSearch } = props;
  const router = useRouter();
  const dispatch = useDispatch();
  const [handleSetTimeout, handleClearTimeout] = useTimeout();
  const languageJson = useLanguage();
  const [optionCities, setOptionCities] = useState<HotelCityOptionAutocompleteType[]>([]);
  const [optionHotels, setOptionHotels] = useState<HotelCityOptionAutocompleteType[]>([]);
  const [optionSelect, setOptionSelect] = useState<HotelCityOptionAutocompleteType[]>([]);
  const [hotelSearchData, setHotelSearchData] = useState<HotelSearchDataType>({
    hotelCity: { group: 'city', label: '', value: '', place: '' },
    rangeDate: {},
    roomPassenger: [
      {
        passengers: {
          adult: {
            value: 1,
            askAges: false,
            ages: [],
          },
          child: {
            value: 0,
            askAges: true,
            ages: [],
          },
        },
      },
    ],
  });
  const [searchHotelText, setSearchHotelText] = useState('');
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const callApi = useRef(false);
  let cancelTokenHotel = useRef(axios.CancelToken.source());
  let cancelTokenCity = useRef(axios.CancelToken.source());

  useEffect(() => {
    const staticDataOptions: HotelCityOptionAutocompleteType[] = (
      staticData as SearchCityNamesResponse[]
    ).map(city => ({
      label: city.cityNames.find(cityName => cityName.language === router.locale)?.value || '-',
      group: 'city',
      value: city.id.toString(),
      place:
        city.country.countryNames.find(countryName => countryName.language === router.locale)
          ?.value || '-',
    }));
    setOptionSelect(staticDataOptions);
  }, [router.locale]);

  useEffect(() => {
    setLoadingAutocomplete(true);
    handleClearTimeout(1000, getDataFromApi);
    handleSetTimeout(1000, getDataFromApi);
    if (!searchHotelText) {
      setLoadingAutocomplete(false);
      const dataModified: HotelCityOptionAutocompleteType[] = (
        staticData as SearchCityNamesResponse[]
      ).map(city => ({
        label: city.cityNames.find(cityName => cityName.language === router.locale)?.value || '-',
        group: 'city',
        value: city.id.toString(),
        place:
          city.country.countryNames.find(countryName => countryName.language === router.locale)
            ?.value || '-',
      }));
      setOptionSelect(dataModified);
      if (typeof cancelTokenHotel.current != typeof undefined) {
        cancelTokenHotel.current.cancel('Operation canceled due to new request.');
        cancelTokenHotel.current = axios.CancelToken.source();
      }
      if (typeof cancelTokenCity.current != typeof undefined) {
        cancelTokenCity.current.cancel('Operation canceled due to new request.');
        cancelTokenCity.current = axios.CancelToken.source();
      }
    }
  }, [searchHotelText]);

  useEffect(() => {
    if (callApi.current) {
      setOptionSelect([...optionCities, ...optionHotels]);
    }
  }, [optionCities, optionHotels]);

  const searchHotelNames = async () => {
    const body: SearchHotelNamesRequest = { query: searchHotelText };
    if (typeof cancelTokenHotel.current != typeof undefined) {
      cancelTokenHotel.current.cancel('Operation canceled due to new request.');
      cancelTokenHotel.current = axios.CancelToken.source();
    }
    try {
      const { data } = await apiSearchHotelNames(body, cancelTokenHotel.current.token);
      const dataModified: HotelCityOptionAutocompleteType[] = data.map(hotel => ({
        label:
          hotel.hotelNames.find(hotelName => hotelName.language === router.locale)?.value || '-',
        group: 'hotel',
        value: hotel.id.toString(),
        place: hotel.city.cityNames.find(city => city.language === router.locale)?.value || '-',
      }));
      setOptionHotels(dataModified);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAutocomplete(false);
    }
  };
  const searchCityNames = async () => {
    const body: SearchCityNamesRequest = { query: searchHotelText };
    if (typeof cancelTokenCity.current != typeof undefined) {
      cancelTokenCity.current.cancel('Operation canceled due to new request.');
      cancelTokenCity.current = axios.CancelToken.source();
    }
    try {
      const { data } = await apiSearchCityNames(body, cancelTokenCity.current.token);
      const dataModified: HotelCityOptionAutocompleteType[] = data.map(city => ({
        label: city.cityNames.find(cityName => cityName.language === router.locale)?.value || '-',
        group: 'city',
        value: city.id.toString(),
        place:
          city.country.countryNames.find(countryName => countryName.language === router.locale)
            ?.value || '-',
      }));
      setOptionCities(dataModified);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAutocomplete(false);
    }
  };

  const groupBy = useMemo((): {
    group: { key: string; label: string };
    items: HotelCityOptionAutocompleteType[];
  }[] => {
    const groups = [
      { key: 'city', label: languageJson.common.city },
      { key: 'hotel', label: languageJson.common.hotel },
    ];
    return groups.map(group => ({
      group: group,
      items: optionSelect.filter(option => option.group === group.key),
    }));
  }, [optionSelect]);

  const updateSearchData = (stateObj: object) => {
    setHotelSearchData({ ...hotelSearchData, ...stateObj });
  };
  const searchHotel = () => {
    const params = generateSearchParamsHotel(hotelSearchData);
    dispatch(hotelSearchSaveSearchData(hotelSearchData));
    dispatch(hotelSearchClearSearchToken());
    dispatch(hotelSearchClearExpirationDate());
    setShowDialogSearch?.(false);
    if (hotelSearchData.hotelCity.group === 'city') {
      dispatch(
        addHotelSearchHistory({
          data: hotelSearchData,
          href: `${BOOKING_URL}/${router.locale}/hotels/searchresults?${params}`,
          id: uuidv4(),
        })
      );

      window.location.href = `${BOOKING_URL}/${router.locale}/hotels/searchresults?${params}`;
    } else if (hotelSearchData.hotelCity.group === 'hotel') {
      dispatch(
        addHotelSearchHistory({
          data: hotelSearchData,
          href: `${BOOKING_URL}/${router.locale}/hotel/${hotelSearchData.hotelCity.place}/${
            hotelSearchData.hotelCity.value
          }/${name_to_url(hotelSearchData.hotelCity.label)}?${params}`,
          id: uuidv4(),
        })
      );
      window.location.href = `${BOOKING_URL}/${router.locale}/hotel/${
        hotelSearchData.hotelCity.place
      }/${hotelSearchData.hotelCity.value}/${name_to_url(
        hotelSearchData.hotelCity.label
      )}?${params}`;
    }
  };
  const getDataFromApi = () => {
    if (searchHotelText) {
      callApi.current = true;
      searchHotelNames();
      searchCityNames();
    }
  };
  const isSearchDisabled = !(
    hotelSearchData.hotelCity.value &&
    hotelSearchData.rangeDate.start &&
    hotelSearchData.rangeDate.end &&
    hotelSearchData.roomPassenger.length > 0 &&
    !isChildWithoutAge(hotelSearchData)
  );
  return (
    <Stack
      id='FRONT_CONTROL-hotel-form'
      mt={1.5}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      alignItems='center'
      spacing={2}>
      <Box width={1}>
        <SelectHotel
          loading={loadingAutocomplete}
          setSearchHotel={setSearchHotelText}
          data={groupBy}
          searchData={hotelSearchData}
          setSearchDataHotelCity={setHotelSearchData}
          searchHotel={searchHotelText}
        />
      </Box>
      <Box width={1}>
        <SelectDate
          labelFrom={languageJson.common.datetime_check_in_out}
          limit={{
            start: toDateObject(new Date()),
            end: toDateObject(new Date(new Date().setFullYear(new Date().getFullYear() + 1))),
          }}
          onChange={(date: DateObject, mode: 'start' | 'end') =>
            updateSearchData({
              rangeDate: {
                ...hotelSearchData.rangeDate,
                [mode]: date,
              },
            })
          }
          value={hotelSearchData.rangeDate}
        />
      </Box>
      <Box width={1}>
        <ResponsiveRoomPassengerSelect
          searchData={hotelSearchData.roomPassenger}
          onChange={updateSearchData}
          label={languageJson.common.passengers}
        />
      </Box>
      {!hiddenSearchBtn && (
        <Stack
          direction='row'
          alignItems='flex-end'>
          <Button
            id='FRONT_CONTROL-hotel-form-submit'
            disabled={isSearchDisabled}
            onClick={searchHotel}
            fullWidth
            variant='contained'
            sx={{
              px: {
                xs: '42px',
                md: '55px',
              },
            }}>
            {languageJson.common.search}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
