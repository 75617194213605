import { useEffect, useState } from 'react';
import type { WeekDay, DateYearMonth } from '@/components/common/date-picker/type';
import MonthDays from '../component/month-days/month-days';
import WeekDays from '../component/week-days/week-days';
import {
  getDayOfMonth,
  isInDateRange,
  trimMonth,
  addMonthToYearMonth,
  isAfterDate,
  isBeforeDate,
} from '../functions';
import type { ViewProps } from '../view-manager';
import { Box, Stack, Typography } from '@mui/material';

type ViewMonthsType = {
  year: number;
  month: number;
  calendarMonth: { days: number; firstDayWeekDay: WeekDay };
  dayLimit?: { start: number; end: number };
  day?: number;
  dayRange?: { start?: number; end?: number };
  monthLimit: {
    next: boolean;
    previous: boolean;
  };
};
export default function DualView(props: ViewProps) {
  const { date, dateRange, calendar, limit, locale, onSelect, currentDate } = props;

  if (date && dateRange) {
    throw new Error('date and dateRange cannot be set simultaneously.');
  }

  const [navigatedMonth, setNavigatedMonth] = useState(0);

  let currentNavigatedMonth = navigatedMonth;
  useEffect(() => {
    setNavigatedMonth(0);
    currentNavigatedMonth = 0;
  }, [dateRange?.start?.month, date?.month]);

  const viewMonths: ViewMonthsType[] = [];

  let months: {
    year: number;
    month: number;
  }[] = [];

  let startOfView: DateYearMonth;

  if (date && limit?.end.month) {
    startOfView = { year: date.year, month: date.month };
  } else if (dateRange && dateRange.start) {
    startOfView = {
      year: dateRange.start.year,
      month: dateRange.start.month,
    };
  } else {
    startOfView = { year: currentDate.year, month: currentDate.month };
  }

  let firstMonth: DateYearMonth = addMonthToYearMonth(startOfView, currentNavigatedMonth);
  months = [firstMonth, addMonthToYearMonth(firstMonth, 1)];

  for (let item of months) {
    const calendarMonth = calendar.getMonth(item.year, item.month);
    let dayLimit;
    if (limit) {
      const { startDay: startLimitDay, endDay: endLimitDay } = trimMonth(
        item.year,
        item.month,
        calendarMonth.days,
        limit
      );
      dayLimit = { start: startLimitDay, end: endLimitDay };
    }

    const day = getDayOfMonth(item.year, item.month, date);

    let dayRange;
    let dayRangeStart = getDayOfMonth(item.year, item.month, dateRange?.start);
    let dayRangeEnd = getDayOfMonth(item.year, item.month, dateRange?.end);

    const firstDayInRange =
      dateRange &&
      dateRange.start &&
      dateRange.end &&
      isInDateRange(
        { year: item.year, month: item.month, day: 1 },
        { start: dateRange.start, end: dateRange.end }
      );

    if (
      dayRangeStart === undefined &&
      dateRange?.start &&
      (dayRangeEnd !== undefined || firstDayInRange)
    )
      dayRangeStart = 0;
    if (
      dayRangeEnd === undefined &&
      dateRange?.end &&
      (dayRangeStart !== undefined || firstDayInRange)
    )
      dayRangeEnd = calendarMonth.days + 1;
    if (dayRangeStart !== undefined || dayRangeEnd !== undefined) {
      dayRange = { start: dayRangeStart, end: dayRangeEnd };
    }

    const monthLimit = {
      next: !!(
        limit &&
        limit.end &&
        isBeforeDate(limit.end, {
          year: item.year,
          month: item.month,
          day: calendarMonth.days,
        })
      ),
      previous: !!(
        limit &&
        limit.start &&
        isAfterDate(limit.start, {
          year: item.year,
          month: item.month,
          day: 1,
        })
      ),
    };

    viewMonths.push({
      year: item.year,
      month: item.month,
      calendarMonth,
      dayLimit,
      day,
      dayRange,
      monthLimit,
    });
  }

  const handleMonthChange = (move: number) => {
    setNavigatedMonth(navigatedMonth + move);
  };

  return (
    <Stack direction='row'>
      {viewMonths.map((viewMonth, index) => (
        <Box
          component='div'
          width='280px'
          m={1}
          key={index}>
          <Stack
            direction='row'
            sx={{
              userSelect: 'none',
            }}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              height='36px'
              flex='0 1 36px'
              sx={{
                visibility: index === 0 ? 'visible' : 'hidden',
                opacity: viewMonth.monthLimit.previous ? 1 : 0.2,
              }}
              onClick={viewMonth.monthLimit.previous ? () => handleMonthChange(-1) : undefined}>
              <Box
                component='span'
                className='icon-chevron-light-left'></Box>
            </Stack>
            <Stack
              alignItems='center'
              justifyContent='center'
              flexGrow='1'
              component='div'>
              <Typography
                fontWeight={800}
                fontSize={16}
                color='black'>
                {locale.months[viewMonth.month - 1]}
              </Typography>
            </Stack>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              height='36px'
              flex='0 1 36px'
              sx={{
                visibility: index === 1 ? 'visible' : 'hidden',
                opacity: viewMonth.monthLimit.next ? 1 : 0.2,
              }}
              onClick={viewMonth.monthLimit.next ? () => handleMonthChange(1) : undefined}>
              <Box
                component='span'
                className='icon-chevron-light-right'></Box>
            </Stack>
          </Stack>
          <WeekDays
            labels={locale.weekDays}
            startDayOfWeek={locale.firstDayOfTheWeek}
          />
          <Box
            sx={{
              padding: '3px 8px 7px',
            }}>
            <MonthDays
              startDayOfWeek={locale.firstDayOfTheWeek}
              firstDayWeekDay={viewMonth.calendarMonth.firstDayWeekDay}
              dayCount={viewMonth.calendarMonth.days}
              limit={viewMonth.dayLimit}
              day={viewMonth.day}
              range={viewMonth.dayRange}
              onSelect={dayNumber =>
                onSelect &&
                onSelect({
                  year: viewMonth.year,
                  month: viewMonth.month,
                  day: dayNumber,
                })
              }
            />
          </Box>
        </Box>
      ))}
    </Stack>
  );
}
