import { LocationType } from '@/services/flights/flight.types';
import {
  type PlaceSearchResponse,
  ResultType,
} from '@/services/places/place-search-translate.types';
import type { OriginDestinationObj } from './origin-destination.types';

export const optionCreator = (
  data: PlaceSearchResponse[],
  language: string,
  className?: string
) => {
  const optionArray: OriginDestinationObj[] = [];
  data.forEach(item => {
    item.cities.forEach(city => {
      if (item.resultType !== ResultType.AIRPORT)
        optionArray.push({
          code: city.cityCode,
          type: LocationType.CITY,
          label: city.cityNames.find(name => name.language === language)?.value || '',
          country: item.countryNames.find(name => name.language === language)?.value || '',
        });
      if (item.resultType !== ResultType.COUNTRY)
        city.airports.forEach(airport => {
          optionArray.push({
            code: airport.airportCode,
            type: LocationType.AIRPORT,
            label: airport.airportNames.find(name => name.language === language)?.value || '',
            class: item.resultType === ResultType.CITY ? className : undefined,
            country: item.countryNames.find(name => name.language === language)?.value || '',
            city: city.cityNames.find(name => name.language === language)?.value || '',
          });
        });
    });
  });
  return optionArray;
};
