import { apiGetHotelInfoList } from '@/services';
import { useEffect, useState } from 'react';
import CardSlider from '../card-slider';
import type { CardItemType } from '../voi-card';
import { useRouter } from 'next/router';
import { BOOKING_URL } from '../constant/constant';
import { name_to_url } from '../functions/name-to-url';
import { useLanguage } from '../language/language';

export type wrapperCardSliderType = {
  title: string;
  subTitle?: string;
  categorizeProperty?: string;
  slidesPerView?: number;
  queryParams: object;
  type: 'Hotel' | 'Tour' | 'other';
  id: number;
};

const SliderWrapper = (props: wrapperCardSliderType) => {
  const { queryParams, type } = props;

  const [items, setItems] = useState<CardItemType[]>();
  const router = useRouter();
  const languageJson = useLanguage();

  useEffect(() => {
    (async () => {
      if (type === 'Hotel') {
        let params: any = {
          page: 1,
          perPage: 10,
          locale: router.locale,
        };
        if (queryParams) {
          params = { ...params, ...queryParams };
        }
        const hotelInfoListResponse = await apiGetHotelInfoList(params);
        setItems(
          hotelInfoListResponse.data.result.map(hotel => ({
            ...hotel,
            link: `${BOOKING_URL}/${router.locale}/hotel/${hotel.cityName}/${
              hotel.hotelId
            }/${name_to_url(hotel.name)}`,
            upTitle: hotel?.cityName,
            title: hotel.name,
            rating: hotel?.rating,
            basePrice: hotel.defaultStartPrice,
            discount: hotel.defaultShowingDiscountPtc ? hotel.defaultShowingDiscountPtc : 0,
            reviewScore: hotel.reviewScore,
            image: hotel.images ? hotel.images[0]?.link : undefined,
            scaleOfPrice: languageJson.common.per_night,
          }))
        );
      }
    })();
  }, []);

  return (
    <>
      {items && (
        <CardSlider
          {...props}
          items={items}></CardSlider>
      )}
    </>
  );
};
export default SliderWrapper;
